.teamHeader {
  background: url(../../assets/img/bh-teamC.jpg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 25vw;
}

.teamHeader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vw;
  background: rgba(0, 0, 0, 0.68); 
  z-index: 1;
} 

.teamMain > h2 {
  margin: 3vw;
  text-align: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}

.teamMain hr {
  margin: 0 20vw 6vw;
  border-top: 2px solid;
}

.teamMain h3 {
  text-align: center;
  margin-top: 3vw;
}

.joinourteam-form{
background-image: url(../../assets/img/professional-gardener-at-work.jpg);
padding: 10px;
}

.container1 {
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10vw, 21vw));
  grid-auto-rows: 19.5vw;
  grid-auto-flow: row dense;
  grid-gap: 1.5vw;
  justify-content: center;
  margin-bottom: 5vw;
  padding-bottom: 2vw;
  overflow-x: hidden;
}

.card-top,
.card-right,
.card-bottom,
.card-left {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 3px 9px 1px rgba(0, 10, 20, 0.2);
  background-color: rgb(250, 250, 250);
}

.card-middle {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  box-shadow: 0px 3px 9px 1px rgba(0, 10, 20, 0.2);
  background-color: rgba(255, 255, 255, 1);
}

.card-top {
  flex-direction: column;
  grid-column: auto/span 1;
  grid-row: auto/span 2;
}

.card-right {
  flex-direction: row-reverse;
  grid-column: auto/span 2;
  grid-row: auto/span 1;
}

.card-bottom {
  flex-direction: column-reverse;
  grid-column: auto/span 1;
  grid-row: auto/span 2;
}

.card-left {
  flex-direction: row;
  grid-column: auto/span 2;
  grid-row: auto/span 1;
}

.card-image {
  display: flex;
}

.card-top .card-image,
.card-bottom .card-image {
  height: 80%;
  width: 100%;
}

.card-left .card-image {
  height: 100%;
  width: 80%;
}

.card-right .card-image {
  height: 100%;
  width: 80%;
}

.card-image img {
  width: 100%;
  object-fit: cover;
}

.card-top img {
  border-radius: 8px 8px 0 0;
}

.card-right img {
  border-radius: 0 8px 8px 0;
}

.card-bottom img {
  border-radius: 0 0 8px 8px;
}

.card-left img {
  border-radius: 8px 0 0 8px;
}

.card-text {
  align-self: center;
  padding: 2vw;
}

.card-top .card-text {
  height: auto;
  width: auto;
  padding-bottom: 3vw;
}

.card-right .card-text {
  height: auto;
  width: 70%;
  padding-left: 3vw;
}

.card-bottom .card-text {
  height: auto;
  width: 90%;
  padding-top: 3vw;
}

.card-left .card-text {
  height: auto;
  width: 70%;
  padding-right: 3vw;
}

.card-text p {
  font-size: 1.1vw;
  margin: 0;
  line-height: 1.3vw;
  color: var(--black-color);
}
.teamMain .careers {
  text-decoration: none;
  display: block; 
  text-align: center;
  font-size: 2.3vw;
  margin: -4vw 0 2vw 0; 
}
.teamMain .careersLink{
  text-decoration: none;
}
.teamMain .careersLink:hover {
  color: var(--yellow-color);
}

.ourvaluesTeam{
margin-top: -3vw;
background-color: rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 991px) {

  .container1 {
    grid-auto-rows: 25vw;
  }

  .card-top .card-text,
  .card-bottom .card-text {
    height: 80%;
    width: 100%;
  }

  .card-left .card-text {
    width: 100%;
  }

  .card-right .card-text {
    width: 100%;
  }

  .card-text span {
    font-size: 1.9vw;
  }

  .card-text p {
    font-size: 1.6vw;
    letter-spacing: 0.05rem;
    line-height: 1.8vw;
  }
  .teamMain .sm {
    width: 55% !important;
    left: 50% !important; /* Move the image to the right by 50% of its container width */
  }
  .teamMain .sm img {
    object-position: 22% !important;
  }
}

@media screen and (max-width: 600px) {
  .container1 {
    grid-template-columns: repeat(auto-fit, minmax(10vw, 26vw));
  }

  .card-text p {
    font-size: 1.8vw;
  }
}

@media only screen and (max-width: 575px) {
  .container1 {
    grid-template-columns: repeat(auto-fit, minmax(10vw, 43vw));
    grid-auto-rows: 38vw;
    grid-gap: 4vw;
  }

  .teamHeader, .teamHeader::before {
    height: 30vw;
  }

  .teamMain p {
    font-size: 4vw;
  }
  .card-text span {
    font-size: 2.7vw;
  }

  .card-text p {
    font-size: 2.9vw !important;
    line-height: 3vw !important;
    letter-spacing: 0.03rem !important;
  }
}

@media only screen and (max-width: 430px) {
  .container1 {
    grid-template-columns: repeat(auto-fit, minmax(10vw, 44vw));
    grid-auto-rows: 42vw;
    grid-gap: 4vw;
  }

  .card-text span {
    font-size: 3.2vw;
  }

  .card-text p {
    font-size: 3.2vw !important;
    line-height: 1.2 !important;
    letter-spacing: 0.04rem !important;
  }

  .teamMain .sm {
    width: 45% !important;
    left: 50% !important; /* Move the image to the right by 50% of its container width */
  }
}
