.sec404 {
  font-size: 1vw;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.75) !important;
  height: 100vh;
}

.sec404 .container404 .one404,
.sec404 .container404 .two404,
.sec404 .container404 .three404,
.sec404 .container404 .p404 {
  width: 60%;
  height: 60%;
  top: 20% !important;
  left: 20% !important;
  min-width: 20vw;
  min-height: 20vw;
}

.sec404 .container404 .one404 .content404,
.sec404 .container404 .two404 .content404,
.sec404 .container404 .three404 .content404,
.sec404 .container404 .p404 .content404 {
  width: 40vw;
  height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: content404 0.8s cubic-bezier(1, 0.06, 0.25, 1) backwards;
}

@keyframes content404 {
  0% {
    width: 0;
  }
}

.sec404 .container404 .one404 .content404 .piece404,
.sec404 .container404 .two404 .content404 .piece404,
.sec404 .container404 .three404 .content404 .piece404,
.sec404 .container404 .p404 .content404 .piece404 {
  width: 20vw;
  height: 10vw;
  display: flex;
  position: absolute;
  border-radius: 10vw;
  z-index: 1;
  animation: piece404Left 8s cubic-bezier(1, 0.06, 0.25, 1) infinite both;
}

@keyframes piece404Left {
  50% {
    left: 80%;
    width: 10%;
  }
}
@keyframes piece404Right {
  50% {
    right: 80%;
    width: 10%;
  }
}

@keyframes text404 {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
}

.sec404 .container404 .text404 .article404 {
  position: absolute;
  top: 40%;
  bottom: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.p404 {
  position: relative;
}

.sec404 .container404 .text404 .article404 p {
  color: white;
  font-size: 1.7vw;
  letter-spacing: 0.15vw;
  text-shadow: 0.9vw 0.9vw 1vw #24263e;
  font-weight: bold;
}

.sec404 .container404 .text404 .article404 .button404 {
  height: 3vw;
  padding: 0 3vw;
  border-radius: 10vw;
  cursor: pointer;
  border: 2px solid #333 !important;
  border: none;
  box-shadow: 0 1vw 2vw rgba(54, 24, 79, 0.5);
  z-index: 3;
  color: #0f0987;
  background-color: rgb(247, 246, 242);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5vw;
  transition: all 0.3s ease;
}

.sec404 .container404 .text404 .article404 .button404:hover {
  box-shadow: 0 0.5vw 1vw -1vw rgba(54, 24, 79, 0.5);
  transform: translateY(5px);
  background: #1c6703;
  color: white;
}

.sec404 .container404 .p404 {
  font-size: 14vw;
  font-weight: 700;
  letter-spacing: 0.3vw;
  color: white;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  animation: anime404 0.6s cubic-bezier(0.3, 0.8, 1, 1.05) both;
  animation-delay: 1.2s;
}

.sec404 .container404 .p404:nth-of-type(2) {
  color: #18184f;
  z-index: 1;
  animation-delay: 1s;
  filter: blur(1vw);
  opacity: 0.8;
}

@keyframes circle404 {
  0% {
    width: 0;
    height: 0;
  }
}

.sec404 .container404 .one404 .content404 .piece404 {
  background: linear-gradient(90deg, #13175a 13.7%, #ffe873 94.65%);
}

.sec404 .container404 .one404 .content404 .piece404:nth-child(1) {
  right: 15%;
  top: 18%;
  height: 3vw;
  width: 8vw;
  animation-delay: 0.5s;
  animation-name: piece404Right;
}

.sec404 .container404 .one404 .content404 .piece404:nth-child(2) {
  left: 15%;
  top: 45%;
  width: 6vw;
  height: 2vw;
  animation-delay: 1s;
  animation-name: piece404Left;
}

.sec404 .container404 .one404 .content404 .piece404:nth-child(3) {
  left: 10%;
  top: 75%;
  height: 2vw;
  width: 4vw;
  animation-delay: 1.5s;
  animation-name: piece404Left;
}

.sec404 .container404 .two404 .content404 .piece404 {
  background: linear-gradient(90deg, #fffcc0 0%, #cfc959 0%, #fff787 100%);
}
.sec404 .container404 .three404 .content404 .piece404 {
  background: linear-gradient(90deg, #ffe873 13.7%, #13175a 94.65%);
}

.sec404 .container404 .two404 .content404 .piece404:nth-child(1) {
  left: 0%;
  top: 25%;
  height: 2vw;
  width: 8vw;
  animation-delay: 2s;
  animation-name: piece404Left;
}

.sec404 .container404 .two404 .content404 .piece404:nth-child(2) {
  right: 15%;
  top: 35%;
  width: 10vw;
  height: 2vw;
  animation-delay: 2.5s;
  animation-name: piece404Right;
}

.sec404 .container404 .two404 .content404 .piece404:nth-child(3) {
  right: 10%;
  top: 80%;
  height: 2vw;
  width: 9vw;
  animation-delay: 3s;
  animation-name: piece404Right;
}

.sec404 .container404 .three404 .content404 .piece404:nth-child(1) {
  left: 25%;
  top: 35%;
  height: 2vw;
  width: 3vw;
  animation-name: piece404Left;
  animation-delay: 3.5s;
}

.sec404 .container404 .three404 .content404 .piece404:nth-child(2) {
  right: 10%;
  top: 55%;
  width: 6vw;
  height: 2.5vw;
  animation-name: piece404Right;
  animation-delay: 4s;
}

.sec404 .container404 .three404 .content404 .piece404:nth-child(3) {
  left: 40%;
  top: 68%;
  height: 2vw;
  width: 4vw;
  animation-name: piece404Left;
  animation-delay: 4.5s;
}

@media (max-width: 991px) {
  .sec404 .container404 .p404 {
    font-size: 15vw;
  }
  .sec404 .container404 .text404 .article404 p {
    font-size: 2.9vw;
  }

  .sec404 .container404 .text404 .article404 .button404 {
    height: 5vw;
    padding: 0 4vw;
    cursor: pointer;
    border: 2px solid #333 !important;
    border: none;
    box-shadow: 0 1vw 2vw rgba(54, 24, 79, 0.5);
    z-index: 3;
    color: #0f0987;
    background-color: rgb(247, 246, 242);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 3vw;
    transition: all 0.3s ease;
  }
}
