
.serviceHeader{
  background: url(../../assets/img/bh-professional-garden-maintenance-north-vancouver.jpg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    height: 25vw;
}

.serviceHeader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vw;
  background: rgba(0, 0, 0, 0.68); 
  z-index: 1;
} 

 .serviceMain{
  min-height: calc(100vh - 25vw); /* Adjust the height to leave space for the footer */
  padding-bottom: 1vw; /* Adjust as needed to create space for the footer */ }

 .serviceMain > p{
  margin: 5rem;
  text-align: center;
  font-size: 2vw;
  text-transform: uppercase;
 }

 .serviceMain hr{
  margin: -1vw 10vw;
  border-top: 2px solid;
}
.joinp{
  font-size: 1.3vw !important;
  letter-spacing: .01rem;
  font-family: "din-condensed-light" !important;
  color: var(--black-color);
  padding:0 2rem;
  margin: 3vw 15vw 3vw 15vw !important;
}

@media(max-width:991px){
  .serviceMain > p{
    margin: 2rem;
    font-size: 3.2vw;
   }
  
  .joinp{
    font-size: 1.9vw !important;
    padding:0;
  }
}


@media (max-width: 575px) {
  .serviceHeader, .serviceHeader::before {
    height: 35vw;
  }
}

@media(max-width:430px){
  .serviceHeader, .serviceHeader::before {
    height: 38vw;
  }

  .serviceMain > p{
    margin: 2rem;
    font-size: 3.5vw;
    z-index:1 !important;
   }
  
  .joinp{
    font-size: 2.7vw !important;
    padding:0;
  }
}