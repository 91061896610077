.projectsGalleryHeader {
  background: url(../../assets/img/bh-professional-garden-maintenance-north-vancouver.jpg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 25vw;
}
.projectsGalleryHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vw;
  background: rgba(0, 0, 0, 0.68);
  z-index: 1;
}

.projectsGalleryMain {
  height: 40vw;
}

.projectsLink {
  color: var(--green-color);
  text-decoration: none;
  position: absolute;
  top: 36.5vw;
  right: 12.5vw;
  font-size: 1rem;
  text-transform: lowercase;
}

.projectsGalleryMain > h2 {
  margin: 3vw;
  text-align: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}
.projectsGalleryMain > h2 a {
  text-decoration: none;
  color: var(--black-color);
}
.projectsGalleryMain > h2 a:hover {
  color: var(--yellow-color);
}
.projectsGalleryMain > p {
  text-align: center;
  font-size: 1.3vw;
  margin: 0 13rem 2rem 13rem;
  width: 70%;
  font-family: "din-light";
  text-transform: uppercase;
}

.projectsGalleryMain hr {
  margin: 0 10vw;
  border-top: 2px solid;
}

.projectsGallery .svg {
  border-radius: 50px !important;
  padding-bottom: 0.5rem;
}

/*--------------------------------------------------------------
# Mansonry Gallery
--------------------------------------------------------------*/

.react-photo-gallery--gallery {
  margin: 0 8vw;
  padding: 0.4rem;
  background: rgba(0, 0, 0, 0.8);
  height: auto;
}

.react-photo-gallery--gallery img {
  padding: 0.3vw;
  border-radius: 8px;
  cursor: zoom-in;
  transition: transform 0.5s ease;
  height: 25vw; /* Adjust the height as needed */
  max-width: 100%; /* Ensures the image does not exceed its container width */
}

.react-photo-gallery--gallery img:hover {
  -ms-transform: scale(1.07);
  -moz-transform: scale(1.07);
  -webkit-transform: scale(1.07);
  -o-transform: scale(1.07);
  transform: scale(1.07);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6); /* Adjust the shadow values as needed */
}

text {
  z-index: 1000;
  color: #fff;
}
/* arrow*/
button.css-xfk84m.css-1ycyyax,
button.css-1h82jk3.css-1ycyyax {
  width: 3rem !important;
  background-color: var(--yellow-color);
  color: #fff !important;
}

.css-1qrom1v {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.css-1ycyyax {
  font-size: 2.4vw !important;
  margin-right: auto;
  margin-left: auto;
}

.projectsGalleryMain .to-top {
  color: #333;
  padding-top: 3.5vw;
  display: inline-block;
  position: relative;
  border-color: #333;
  text-decoration: none;
  transition: all 0.3s ease-out;
  top: -6vw;
  left: 2%;
  font-size: 1vw !important;
  text-transform: uppercase;
  z-index: 1000;
}
.to-top:before {
  content: "▲";
  transform: rotate(30deg);
  font-size: 1.6vw;
  position: absolute;
  top: 0%;
  left: 50%;
  margin-left: -1vw;
  border: solid 0.26vw #333;
  border-radius: 5vw;
  width: 2.5vw;
  height: 2.6vw;
  padding-left: 0.15vw;
  line-height: 2.1vw;
  border-color: inherit;
  transition: transform 0.5s ease-in;
  z-index: 1000;
}

.to-top:hover {
  color: var(--yellow-color);
  border-color: var(--yellow-color);
}
.to-top:hover:before {
  transform: rotate(390deg);
}
.css-zjq1i3 {
  height: auto;
  max-height: 100vh !important;
  width: 100vw !important;
  user-select: none;
  margin-left: auto !important;
  margin-right: auto !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
@media screen and (min-width: 1645px) {
  .react-photo-gallery--gallery img {
    width: 33%;
  }
}

@media screen and (min-width: 3756px) {
  .react-photo-gallery--gallery {
    margin: 0 10vw;
  }
  .react-photo-gallery--gallery img {
    width: 33.2%;
  }
}
@media screen and (max-width: 991px) {
  /* coming soon */
  .projectsGalleryMain h3 {
    text-align: center;
    margin: 7vw 0;
  }
  .react-photo-gallery--gallery img {
    height: 27vw;
  }

  .beeGif {
    width: 40%;
    margin-bottom: 19vw !important;
  }

  .projectsGalleryMain .to-top {
    padding-top: 4vw ;
    top: -8vw;
    font-size: 1.2vw !important;
  }

  .to-top::before {
    font-size: 2vw;
    top: 0%;
    left: 45%;
    width: 3vw;
    height: 3vw;
    line-height: 2.3vw;
  }
}
@media only screen and (max-width: 575px) {
  .projectsGalleryHeader, .projectsGalleryHeader::before {
    height: 30vw;
  }
  
  .react-photo-gallery--gallery img {
    height: 35vw;
  }

  .css-1ycyyax {
    font-size: 4vw !important;
  }

  .to-top {
    padding-top: 4.5vw;
    top: -12vw;
    font-size: 1vw !important;
  }
  .projectsGalleryMain .to-top {
    color: #333;
    padding-top: 3.5vw;
    display: inline-block;
    position: relative;
    border-color: #333;
    text-decoration: none;
    transition: all 0.3s ease-out;
    top: -8.5vw;
    left: 2%;
    font-size: 1vw !important;
    text-transform: uppercase;
    z-index: 1000;
  }

  .to-top::before {
    font-size: 2.5vw;
    left: 45%;
    width: 3.1vw;
    height: 3.1vw;
    padding-left: -3vw !important;
    line-height: 2.5vw;
  }
  .projectsGalleryMain .to-top {
    font-size: 1.5vw !important;
  }
}
@media only screen and (max-width: 430px) {
  .projectsGalleryMain > h2 {
    font-size: 3vw;
  }
  .react-photo-gallery--gallery {
    margin: 0 14vw;
    padding: 0.4rem;
    background: rgba(0, 0, 0, 0.8);
    height: auto;
  }

  .react-photo-gallery--gallery img {
    padding: 0.4rem;

    height: auto; /* Allows the image's height to adjust proportionally */
  }
}
