.container-grubgone {
  overflow: hidden !important ;
}

.hero {
  background-image: url(../../../assets/img/grub-control-lawn-care-tips.png);
  background-size: contain;
  min-height: 29vw !important;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.bgrubcycle {
  background-color: rgba(0, 0, 0, 0.8); /* Dark background color */
  color: var(--white-color);
  justify-content: space-between !important;
}

.grubText {
  max-width: calc(35% - 0.5vw);
  margin-top: 2.5vw;
}

.grubProductText h3 {
  font-size: 1.9vw;
}

.grubImg {
  max-width: calc(65% - 0.5vw);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2vw;
}

.timesOfTheYearSection {
  margin: 0 auto;
  padding-bottom:0 !important;
}

.timesOfTheYearSection h2 {
  font-size: 3vw;
  letter-spacing: 0.5vw;
  margin-bottom: 5vw;
}

.timesOfTheYearSection .post-item {
  transition: 0.3s;
  text-align: center; /* Center text */
}

.timesOfTheYearSection .post-description p {
  margin: 1.5vw 6vw;
  font-size: 1.3vw;
  text-align: justify;
  letter-spacing: 0.04rem;
  font-family: "din-light";
}

.timesOfTheYearSection .post-description p strong {
  font-family: "din-condensed";
  letter-spacing: 0.2rem;
}

.timesOfTheYearSection .post-item .post-img {
  overflow: hidden;
  border-radius: 50%;
  width: 15vw; /* Adjust the size of the circle */
  height: 15vw; /* Adjust the size of the circle */
  margin: 0 auto; /* Center the circle */
}

.timesOfTheYearSection .post-item .post-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
}

.timesOfTheYearSection .post-item .post-content {
  margin-top: 2vw; /* Adjust the spacing between image and text */
}

.timesOfTheYearSection .post-item .post-title {
  font-size: 1.5vw;
  color: var(--color-secondary);
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 1.5vw;
}

.timesOfTheYearSection .post-item:hover .post-title {
  color: var(--color-primary);
}

.timesOfTheYearSection .col-33 {
  flex: 33%;
  padding: 0 5vw;
}

.bshadow {
  width: 92vw;
  margin: 0 auto;
}

.grubBannerTitle {
  font-size: 2vw !important;
}

.grubBannerText {
  font-size: 1.5vw !important;
  width: 75% !important;
  text-align: justify;
}

.grubProductImg {
  padding-top: 3.5vw;
  padding-left: 2vw;
}
.internal-link {
  color: var(--black-color);
  text-decoration: underline;
}

.cta-button {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.cta-button:hover {
  background-color: #45a049;
}

@media (min-width: 1200px) {
  .container-grubgone {
    max-width: 100vw;
    overflow: hidden !important ;
  }
}

@media (max-width: 991px) {
  .timesOfTheYearSection{
    padding-bottom: 5vw !important;
  }
  .timesOfTheYearSection .post-title {
    font-size: 1.9vw !important;
  }

  .timesOfTheYearSection h2 {
    margin-bottom: 1vw;
  }

  .circlexyear {
    padding: 0 4vw !important;
  }

  .grubProduct {
    display: flex;
    justify-content: center !important;
  }

  .grubProductText {
    margin: 5vw auto 0 5vw !important;
  }

  .grubProductText p {
    font-size: 1.9vw;
  }
  .grubBannerTitle{
    font-size: 2.2vw !important;
  }
  .grubBannerText{
    font-size: 1.8vw !important;
  }
}

@media (max-width: 768px) {
  .timesOfTheYearSection h2 {
    margin-bottom: 0vw;
  }

  .grubText {
    max-width: calc(80% - 0.5vw);
    margin: 0 auto;
  }

  .grubImg {
    max-width: calc(85% - 0.5vw);
    padding-top: 2vw;
    margin: 0 auto;
  }
  .grubProductImg {
    width: 90%;
  }
  .grubBannerTitle {
    font-size: 2.3vw !important;
  }

  .grubBannerText {
    width: 85% !important;
    font-size: 0.9rem !important;
  }

  .grubProductText {
    margin: 4vw auto 0 auto !important;
    padding-left: 4vw;
  }
}
@media (max-width: 576px) {
  .grubBannerTitle {
    font-size: 2.7vw !important;
  }
  .grubBannerText {
    width: 55vw !important;
  }
  .grubBannerButton {
    margin-top: -15vw !important;
  }
}

@media (max-width: 430px) {
  .grubBannerTitle {
    font-size: 3.8vw !important;
  }
  .bgrubcycle {
    padding: 5vw 0 !important;
  }

  .timesOfTheYearSection .section-header h2 {
    font-size: 1.1rem !important;
    margin-bottom: -3vw;
  }

  .timesOfTheYearSection .post-title {
    font-size: 3.5vw !important;
  }

  .grubProductText p {
    font-size: 3.5vw !important;
  }

  .grubProductText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2vw !important;
  }

  .grubImg {
    margin-top: 5vw;
  }

  .grubProductImg {
    width: 100%;
    margin: 0 10vw !important;
  }
}
