.contactUsHeader {
  background: url(../../assets/img/bh-professional-garden-maintenance-north-vancouver.jpg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 25vw;
}

.contactUsHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vw;
  background: rgba(0, 0, 0, 0.68);
  z-index: 1;
}

.contactUsMain h2 {
  margin: 3vw;
  text-align: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}
.contactUsMain {
  overflow: hidden;
}

.contactUsMain hr {
  margin: 0 9vw;
  border-top: 2px solid;
}

.contact-form-container {
  margin-top: 4.5vw !important;
  background: rgba(181, 179, 179, 0.445) !important;
}

.contact-us {
  position: relative;
  width: 12vw;
  background: var(--blue-color);
  overflow: hidden;
}

.contact-us:before {
  position: absolute;
  content: "";
  bottom: -9.12vw;
  left: -6.25vw;
  height: 19vw;
  width: 34vw;
  background: var(--green-color);
  transform: rotate(25deg);
}

.contact-us:after {
  position: absolute;
  content: "";
  bottom: -5.12vw;
  height: 18vw;
  width: 45vw;
  background: var(--yellow-color);
  transform: rotate(-25deg);
}

.contact-header {
  color: white;
  position: absolute;
  transform: rotate(-90deg);
  top: 12.5vw;
  left: -3.12vw;
}

.contact-header h3 {
  font-size: 1.7vw;
}

.social-bar {
  position: absolute;
  bottom: -1%;
  margin-left: -2%;
  z-index: 1;
}

.social-bar ul {
  list-style-type: none;
}

.social-bar ul li {
  display: inline-block;
  width: 2vw;
  height: 2vw;
  line-height: 2vw;
  text-align: center;
  margin-right: 0.1vw;
  font-size: 1.4vw;
  color: white !important;
}

.contactUsMain .header {
  padding: 0vw 0;
  color: #444;
}

.contactUsMain .header h2 {
  margin: 2vw;
  font-size: 3vw !important;
  font-weight: 300;
}

.contactUsMain .header p {
  font-size: 1.4vw !important;
  padding: 0 2vw;
  margin-bottom: 3vw;
}

.address,
.email,
.phone {
  text-align: center;
  padding: 1vw 0;
  color: #444;
}

.address p,
.email p,
.phone p {
  font-size: 1.35vw;
  font-weight: 300;
  margin-bottom: 0vw;
}
.email a,
.phone a {
  text-decoration: none;
  color: var(--black-color);
  letter-spacing: 0.03rem;
}
.address i,
.email i,
.phone i {
  color: var(--yellow-color);
  font-size: 1.5vw;
  margin-bottom: 1vw;
}

.contact-form form {
  position: relative;
  width: 65vw;
  height: auto;
  margin: auto auto;
  padding: 1.2vw;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: white;
}

.contact-form form input,
form textarea {
  background: white;
  display: block;
  margin: 1vw auto;
  width: 100%;
  border: 0;
}

.contact-form form input {
  height: 2vw;
  line-height: 2vw;
  outline: 0;
  border-bottom: 1px solid rgba(68, 68, 68, 0.3);
  font-size: 1.1vw;
  color: rgba(68, 68, 68, 0.8);
}

.contact-form form textarea {
  border-bottom: 1px solid rgba(68, 68, 68, 0.3);
  resize: none;
  outline: none;
  font-size: 1.1vw;
  font-family: lato;
  color: rgba(68, 68, 68, 0.8);
}

.contact-form {
  font-size: 1.1vw;
  padding-bottom: 2vw;
}

.contact-form-container {
  max-width: 100%;
  margin: 2% 4%;
  font-size: 0.9vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr 2fr;
  grid-template-areas: "contact-us header header header" "contact-us address phone email" "contact-us contact-form contact-form contact-form";
}

.contact-us {
  grid-area: contact-us;
}

.header {
  grid-area: header;
}

.address {
  grid-area: address;
}

.phone {
  grid-area: phone;
}

.email {
  grid-area: email;
}
.contactUsMain .header h2 {
  margin-left: -2vw !important;
}
.contact-form {
  grid-area: contact-form;
}

.map_sec {
  padding: 2.5vw 0px;
}

.map_inner h4,
.map_inner p {
  color: var(--black-color);
  text-align: center;
  font-size: 2.2vw;
}

.map_inner p {
  font-size: 1.2vw;
}

.map_bind {
  margin-top: 2.5vw;
  border-radius: 4px;
  overflow: hidden;
}

.buttonSubmit {
  position: absolute;
  left: 50%;
  bottom: -1.2vw;
  padding: 0.6vw 2.5vw;
  border-radius: 25rem !important;
  transform: translate(-50%, -50%);
  color: #fff;
  background: var(--green-color);
  text-decoration: none;
  font-size: 1.3vw;
  display: inline-block;
  text-transform: uppercase;
  border: 2px solid #fff;
}

@media (max-width: 991px) {
  .contactUsMain .header h2 {
    margin-left: -7vw !important;
  }
  .contactUsMain .header p {
    margin-left: -11vw;
    font-size: 2vw !important;
    text-align: center;
  }
  .contact-form form {
    width: 77vw !important;
    margin-left: -9.5vw;
  }
  .address p,
  .address i,
  .email p,
  .email i,
  .phone p,
  .phone i {
    font-size: 1.6vw;
    margin-left: -7vw;
  }
  .address h4,
  .email h4,
  .phone h4 {
    font-size: 2.1vw;
    margin-left: -7vw;
  }

  .contact-form form input,
  .input-contact,
  .contact-form form textarea {
    font-size: 1.7vw !important;
  }

  .buttonSubmit {
    height: 4.1vw;
    margin-top: -2.5%;
    padding-top: 0.8vw;
    font-size: 2vw;
  }
  .social-bar {
    margin-left: -21%;
  }

  .social-bar ul li {
    width: 2.3vw;
    height: 2.3vw;
    font-size: 1.8vw;
  }
}

@media (max-width: 430px) {
  .contactUsHeader,
  .contactUsHeader::before {
    height: 32vw;
  }

  .contactUsMain h2 {
    font-size: 3vw !important;
  }
  .contact-us {
    width: 8vw;
  }
  .contact-header {
    top: 17vw !important;
    right: -7vw !important;
  }

  .contactUsMain .header h2 {
    font-size: 3.8vw !important;
    text-align: center;
  }

  .contact-header h3 {
    font-size: 3vw;
  }
  .contactUsMain .header p {
    font-size: 3vw !important;
    text-align: center;
  }
  .contact-form form {
    width: 80vw !important;
    margin-left: -13vw !important;
    padding-top: 2vw !important;
  }

  .address p,
  .email p,
  .phone p {
    font-size: 2.4vw;
    margin-left: -16vw;
    margin-right: 3vw;
    padding: 0 4vw;
  }

  .address i,
  .email i,
  .phone i {
    font-size: 3.1vw;
    margin-left: -12vw;
    padding-bottom: 1vw;
    margin-right: 6vw;
  }

  .address h4,
  .email h4,
  .phone h4 {
    font-size: 2.8vw;
    margin-left: -12vw;
    margin-right: 6vw;
  }

  .contact-form form input,
  .input-contact,
  .contact-form form textarea {
    font-size: 3.2vw !important;
    margin-bottom: 2vw;
    padding-top: 2vw !important;
  }
  .social-bar {
    margin-left: -65%;
    padding-right: 1vw;
  }
  .social-bar ul li {
    padding: 1vw 1vw;
    font-size: 3vw;
  }

  .buttonSubmit {
    height: 4.1vw;
    top: 96% !important;
    padding-top: 1vw;
    font-size: 3vw;
    width: 30%;
    height: 7.5%;
  }
}
