.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2vw; /* Adjust height as needed */
    overflow: hidden;
    position: relative;
    margin: 3vw 0 -1vw 0;

  }
  
  .carousel-text {
    font-size: 2vw;
    font-weight: bold;
    position: absolute;
    opacity: 0;
     text-align: center !important;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease-in-out forwards;
    color: var(--yellow-color);
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }