.serviceItemsHeader {
  background: url(../../assets/img/bh-professional-garden-maintenance-north-vancouver.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 25vw;
}

.serviceItemsHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vw;
  background: rgba(0, 0, 0, 0.68);
  z-index: 1;
}

.serviceItemsMain {
  height: 40vw;
}

.serviceItemsMain > h2 {
  margin: 3vw;
  text-align: center;
  font-size: 2vw;
  text-transform: uppercase;
}

.serviceItemsMain > p {
  margin: 5vw 0;
  text-align: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}

.serviceItemsMain hr {
  margin: 0 10vw 4vw 10vw;
  border-top: 2px solid;
}

.transfNone:hover .cover {
  transform: rotateX(0deg);
}

.transfNone:hover .cover:after {
  background-color: var(--yellow-color);
}

.hero1 {
  background-image: url(../../assets/img/fall-cleanup-services-by-bur-han.jpg);
  background-size: contain;
  min-height: 29vw !important;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1400px) {
  .serviceItemsMain .container,
  .serviceItemsMain .container-lg,
  .serviceItemsMain .container-md,
  .serviceItemsMain .container-sm,
  .serviceItemsMain .container-xl,
  .serviceItemsMain .container-xxl {
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .ldmt {
    margin: 0 6vw;
  }
  .transfNone {
    height: 19vw !important;
  }
  .transfNone .cover:before {
    box-shadow: 1vw 1vw 1vw rgba(0, 0, 0, 1);
    top: 8%;
    left: 8%;
    right: 8%;
    bottom: 8%;
  }

  .transfNone h3 {
    font-size: 2vw !important;
  }
  
  
}

@media (max-width: 575px) {
  .ldmt {
    margin: 0 22vw;
  }

  .transfNone {
    height: 25vw !important;
  }

  .transfNone h3 {
    font-size: 2.7vw !important;
  }
  .nHeader h1{
    font-size: 4.5vw !important;
  }
}

@media (max-width: 430px) {
  .serviceItemsHeader,
  .serviceItemsHeader::before {
    height: 32vw;
  }

  .serviceItemsMain > h2 {
    margin: 5vw;
    text-align: center;
    font-size: 3vw !important;
    text-transform: uppercase;
  }
  .nHeader h1{
    font-size: 4vw !important;
    z-index: 1000 !important;
  }
}

/*------------------------------------------------
    HONEYCOMB
  -------------------------------------------------*/
.hexagon-menu {
  width: 90% !important;
  margin-left: 4vw;
}

.hex-cols {
  flex: 0 0 auto;
  margin-left: auto !important;
  margin-right: auto !important;
  margin: 1.5vw 0 2vw 0;
}

/* hex borders */
.hexagon-item {
  width: 8vw;
  height: 17.8vw;
  float: left;
  margin-left: 6.5vw;
  z-index: 0;
  position: relative;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  transform: rotate(30deg);
}

.hexagon-item:hover {
  z-index: 1;
}

.hexagon-item:hover .hex-item:last-child {
  opacity: 1;
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

.hexagon-item:hover .hex-item:first-child {
  opacity: 1;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.hexagon-item:hover .hex-item:first-child div:before,
.hexagon-item:hover .hex-item:first-child div:after {
  height: 5px;
}

.hexagon-item:hover .hex-item div::before,
.hexagon-item:hover .hex-item div::after {
  background-color: var(--yellow-color);
}

.hexagon-item:hover .hex-content svg {
  -webkit-transform: scale(0.97);
  -moz-transform: scale(0.97);
  -ms-transform: scale(0.97);
  -o-transform: scale(0.97);
  transform: scale(0.97);
}

/*side and size border hex*/
.hex-item {
  position: absolute;
  top: 0;
  left: 2.87vw;
  width: 8vw;
  height: 13.3vw;
}

.hex-item:first-child {
  z-index: 0;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hex-item:last-child {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
}

/* line border*/
.hex-item div {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 8vw;
  height: 14vw;

  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}

/* line border*/
.hex-item div::before,
.hex-item div::after {
  background-color: #1e2530;
  content: "";
  position: absolute;
  width: 8vw;
  height: 0.2vw;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-item div:before {
  top: 0;
}

.hex-item div:after {
  bottom: 0;
}

.hex-item div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.hex-item div:nth-child(2) {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  transform: rotate(60deg);
}

.hex-item div:nth-child(3) {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
}

.hex-content {
  color: #fff;
  display: block;
  height: 25vw;
  margin: 0 auto;
  position: relative;
  text-align: center;
  transform: rotate(-30deg);
  width: 21vw;
}

.hex-content .hex-content-inner {
  left: 10.3vw;
  position: absolute;
  top: 5.5vw;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hex-content .icon {
  display: block;
  font-size: 2.2vw;
  line-height: 2vw;
  margin-bottom: 1vw;
}

.hex-content .title {
  display: block;
  font-family: "din-light" !important;
  font-size: 0.8vw;
  letter-spacing: 0.1vw;
  line-height: 1.2vw;
  text-transform: uppercase;
}

.hex-content svg {
  left: 3.1vw;
  position: absolute;
  top: -2.25vw;
  transform: scale(0.87);
  z-index: -1;
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-content:hover {
  color: #fff;
}

.hexagon-item:first-child {
  margin-left: -1.3%;
}

.hexagon-item:nth-child(n + 5) {
  margin-top: -9%;
}

.hexagon-item:nth-child(8) {
  margin-left: 23.5%;
}

.hexagon-item:hover .icon i {
  color: var(--yellow-color);
  transition: 0.6s;
}

/* .hexagon-item:hover{
    animation: none !important;   
    transform: none;
    } */

.hexagon-item:hover .title {
  -webkit-animation: focus-in-contract 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: focus-in-contract 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  /* transform: rotate(30deg); */
}

/***************************/

@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 2147px) {
  .serviceItemsMain .container {
    width: 90vw;
    padding-left: 3vw;
  }
  .hex-cols {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .hexagon-item {
    width: 8.1vw;
    height: 16.2vw;
    margin-left: 6.4vw;
  }

  .hexagon-item:nth-child(n + 5) {
    margin-top: -6%;
  }

  .hexagon-item:nth-child(8) {
    margin-left: 22.7%;
  }
}
@media (min-width: 2014px) {
  .hexagon-menu {
    width: 60vw !important;
  }
  .hex-cols {
    margin: 2vw auto 5vw auto;
  }

  .hexagon-item {
    width: 8.1vw;
    height: 16.2vw;
    margin-left: 6.4vw;
  }

  .hexagon-item:nth-child(n + 5) {
    margin-top: -6%;
  }

  .hexagon-item:nth-child(8) {
    margin-left: 22.7%;
  }
}

@media (min-width: 992px) {
  .hex-cols {
    width: 67.4vw;
  }
}

@media (max-width: 991px) {
  .serviceItemsMain .mb-5 {
    margin-top: 3vw;
    margin-bottom: 7vw !important;
  }

  /* hex borders */
  .hexagon-item {
    width: 8.9vw;
    height: 17.6vw;
    margin-left: 7.1vw;
  }

  .hex-cols {
    margin-top: 0.9vw !important;
    width: 71vw !important;
  }

  .hex-item:first-child {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  .hex-content svg {
    left: 3vw;
    transform: scale(0.97);
  }

  .hex-content .title {
    font-size: 0.93vw !important;
    line-height: 1.3;
  }

  .hexagon-item:nth-child(n + 5) {
    margin-top: -6%;
  }

  .hexagon-item:nth-child(8) {
    margin-left: 24.3%;
  }
}

@media (max-width: 768px) {
  /* hex borders */
  .hexagon-item {
    width: 9.2vw;
    height: 18vw;
    margin-left: 7.4vw;
  }

  .hex-cols {
    width: 74vw !important;
  }

  .hex-item:first-child {
    -webkit-transform: scale(1.14);
    -moz-transform: scale(1.14);
    -ms-transform: scale(1.14);
    -o-transform: scale(1.14);
    transform: scale(1.14);
  }

  .hex-content svg {
    left: 3vw;
    transform: scale(0.99);
  }

  .hexagon-item:nth-child(n + 5) {
    margin-top: -5.5%;
  }

  .hexagon-item:nth-child(8) {
    margin-left: 24.5%;
  }
}

@media (max-width: 575px) {
  .serviceItemsMain .mb-5 {
    margin-bottom: 2vw !important;
  }

  /* hex borders */
  .hexagon-item {
    width: 9.4vw;
    height: 18.3vw;
    margin-left: 7.9vw;
  }

  .hex-cols {
    width: 77.5vw !important;
    margin-top: 3vw !important;
    margin-bottom: 4vw !important;
  }

  .hex-item:first-child {
    -webkit-transform: scale(1.17);
    -moz-transform: scale(1.17);
    -ms-transform: scale(1.17);
    -o-transform: scale(1.17);
    transform: scale(1.17);
  }

  .hex-content svg {
    left: 3.1vw;
    top: -2.1vw;
    transform: scale(1.03);
  }

  .hex-content .title {
    font-size: 1.05vw !important;
    line-height: 1.3;
  }

  .hexagon-item:nth-child(8) {
    margin-left: 26.2%;
  }
}

@media (max-width: 430px) {
  .hex-cols {
    width: 84vw !important;
    margin-top: 3vw !important;
    margin-bottom: 4vw !important;
  }
  /* hex borders */
  .hexagon-item {
    width: 10.1vw;
    height: 20.1vw;
    margin-left: 8.7vw;
  }

  .hex-content .title {
    font-size: 1.12vw !important;
  }

  .hex-item:first-child {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
  }

  .hex-content svg {
    left: 3vw;
    top: -2.1vw;
    transform: scale(1.14);
  }

  .hexagon-item:nth-child(8) {
    margin-left: 26.3%;
  }
}

/*--------------------------------------------------------------
  # banner section
  --------------------------------------------------------------*/

.banner-section,
.banner-section2 {
  margin-bottom: 6vw;
  padding: 2.1vw 4vw;
  background-color: #23262d;
}

.heading {
  font-size: 5vw;
  font-weight: 700;
  color: #fff;
}
.sndheading {
  font-size: 3.2vw !important;
  color: #fff;
}
.sndheading span {
  color: var(--yellow-color);
}
.sndheading1 {
  font-size: 2.8vw !important;
  text-align: center;
  line-height: 3vw;
  margin-bottom: -1vw;
  text-transform: uppercase;
  color: #fff;
}

.banner-section .heading span,
.banner-section2 {
  color: var(--yellow-color);
}

.banner-section .text {
  margin: 1.5vw 4vw 0 4vw;
  line-height: 2.4vw;
  font-size: 1.6vw;
  letter-spacing: -0.1vw;
  color: #fff;
  font-family: "din-light";
}
@media (min-width: 2146px) {
  .banner-section2 .sndheading {
    font-size: 3.6vw !important;
  }
}
@media (min-width: 1400px) {
  .banner-section {
    padding: 2.6vw 5.5vw;
  }
}
@media (max-width: 991px) {
  .heading {
    text-align: center;
    margin-bottom: 3vw;
  }

  .banner-section .text {
    line-height: 2.4vw;
    font-size: 1.9vw;
    margin: 0 15vw;
    text-align: center;
    letter-spacing: 0.02rem;
  }
}

@media (max-width: 575px) {
  .ctasection {
    flex: 0 0 33% !important;
  }
}

@media (max-width: 430px) {
  .heading {
    font-size: 5vw !important;
    margin-top: 1vw;
  }

  .banner-section .text {
    line-height: 3.5vw;
    font-size: 2.8vw;
    margin: 2% !important;
  }
}

/*--------------------------------------------------------------
  #site half section
  --------------------------------------------------------------*/

/* Container for the section */
.serviceItemsMain .section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
  margin: 0 !important;
  padding: 1vw 2vw 5vw 2vw;
}

/* Image container */
.image-container {
  flex: 1 1 calc(50% - 0.5vw);
  /* 50% width with 0.5vw space on each side */
  max-width: calc(50% - 0.5vw);
  /* Maximum width with 0.5vw space on each side */
  box-sizing: border-box;
  text-align: center;
  /* Center the content horizontally */
}

/* Image styles */
.image-container img {
  width: 98%;
  height: auto;
  display: block;
}

/* Text container */
.text-container {
  flex: 1 1 calc(50% - 0.5vw);
  /* 50% width with 0.5vw space on each side */
  max-width: calc(50% - 0.5vw);
  /* Maximum width with 0.5vw space on each side */
  box-sizing: border-box;
}

/* Text styles */
.text-container h3 {
  font-size: 2vw;
  margin-bottom: 1.5vw;
}

.text-container p {
  font-size: 1.3vw;
  line-height: 1.3;
  font-family: "din-light" !important;
  letter-spacing: 0.08vw;
  text-align: justify;
}

.text-container ul li {
  font-size: 1.2vw;
  line-height: 1.2;
  margin-left: -1rem !important;
  font-family: "din-light" !important;
  padding-bottom: 0.5vw;
  letter-spacing: 0.08vw;
  text-align: justify;
}

.liNumbers {
  font-size: 1.3vw;
  text-decoration: underline;
  padding-bottom: 0.5vw;
  letter-spacing: 0.08vw;
  font-family: "din-light" !important;
}

.headingImageText {
  font-size: 2.2vw;
  position: relative;
}

.headingImageText:before {
  content: "";
  top: 100%;
  left: 0;
  position: absolute;
  width: 4vw;
  height: 0.15vw;
  background: #71bc42;
}

.ml-lg-auto {
  margin-left: auto !important;
}

.caption {
  font-size: 1.2vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #8f989f;
}

.serviceItemsMain .section-container strong {
  font-family: "roboto", sans-serif;
}
/* Responsive styles */

@media screen and (max-width: 991px) {
  /* Container for the section */
  .serviceItemsMain .section-container {
    padding: 0 2vw;
  }
  .serviceItemsMain .section-container .px-5 {
    padding-left: 2vw !important;
    padding-right: 1vw !important;
  }
  /* Image container */
  .image-container {
    flex: 1 1 calc(80% - 0.5vw);
    /* 50% width with 0.5vw space on each side */
    max-width: calc(80% - 0.5vw);
    /* Maximum width with 0.5vw space on each side */
    padding: 2vw !important;
  }

  /* Text container */
  .text-container {
    flex: 1 1 calc(80% - 0.5vw);
    /* 50% width with 0.5vw space on each side */
    max-width: calc(80% - 0.5vw);
    /* Maximum width with 0.5vw space on each side */
    padding: 2vw !important;
  }

  .text-container h3 {
    font-size: 3vw;
  }

  .text-container p {
    font-size: 1.9vw;
    line-height: 1.3;
    letter-spacing: 0.08vw;
  }

  .text-container ul li {
    font-size: 1.9vw;
    line-height: 1.3;
    margin-left: -1rem !important;
  }

  .liNumbers {
    font-size: 2vw;
  }

  .caption {
    font-size: 1.5vw;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #8f989f;
  }
}

@media (max-width: 430px) {
  .section-container {
    margin: 4vw 0vw !important;
  }

  .serviceItemsMain .section-container .px-5 {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
  }
  .image-container {
    flex: 1 1 85% !important;
    max-width: 85% !important;
    margin-bottom: 3vw;
  }

  .text-container {
    flex: 1 1 90%;
    max-width: 90%;
    margin-bottom: 3vw;
  }

  .text-container ul li {
    font-size: 3.5vw;
    line-height: 1.3;
    margin-left: -1.4rem !important;
    margin-bottom: 2vw;
  }
  .liNumbers {
    font-size: 3.6vw;
  }

  .image-container {
    flex: 1 1 95%;
    max-width: 95%;
    margin-bottom: 3vw;
  }

  .text-container h3 {
    font-size: 4vw;
    line-height: 1;
    letter-spacing: 0.1rem;
    margin-bottom: 3vw;
  }

  .text-container p {
    font-size: 3.5vw;
    line-height: 1.3;
    margin-bottom: -3vw;
  }

  .text-container .caption {
    font-size: 2.4vw;
    letter-spacing: 0.2em;
  }
}

.numberTitle {
  padding: 0.8vw 0;
  font-size: 2vw;
}
/*--------------------------------------------------------------
  # second banner section
  --------------------------------------------------------------*/

.secondBanner {
  background: #22252b;
  padding: 2vw 4.8vw;
  margin-top: 5vw;
}
.secondBanner .container {
  max-width: 90%;
}
.secondBanner h3 {
  color: #fff;
  font-size: 1.8vw;
  text-transform: uppercase;
}

.secondBanner p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1vw;
  letter-spacing: 0.01rem;
}

.secondBanner .call-to-action {
  display: block;
  width: 100%;
  float: left;
}

.secondBanner .call-to-action a.get-a-quote,
.banner-section2 .call-to-action a.get-a-quote {
  float: right;
  display: flex;
  margin: 5% 0;
  padding: 0.5vw 3.5vw;
  background: #1e7005;
  text-decoration: none;
  color: #fff;
  font-size: 1.5vw;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 2vw;
  -webkit-box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.19);
  -ms-box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.19);
  -o-box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.19);
}
.banner-section2 .call-to-action a.get-a-quote {
  margin: 5% 10%;
}

.residential2pic{
  height:50vw !important;
}
.landscapeHeightImg{
  height:58vw !important;
}
.strataHeightImg{
  height:51vw !important;
}
.commercialHeightImg{
  height:44vw !important;
}
@media screen and (max-width: 991px) {
  .secondBanner .container {
    max-width: 82% !important;
  }

  .landscapeHeightImg{
    height:80vw !important;
    margin-bottom: 2vw !important;
  }
  .strataHeightImg{
    height:80vw !important;
    margin-bottom: 2vw !important;
  }
  .commercialHeightImg{
    height:80vw !important;
  }

  .secondBanner {
    padding: 2vw 0;
    margin-top: 5vw;
  }

  .secondBanner h3 {
    font-size: 1.3rem;
    width: 60vw;
  }

  .secondBanner p {
    font-size: 0.9rem;
    width: 60vw;
    letter-spacing: 0.05rem;
  }

  .secondBanner .call-to-action a.get-a-quote,
  .banner-section2 .call-to-action a.get-a-quote {
    font-size: 2vw;
    padding: 0.5vw 2.5vw;
  }
}

@media screen and (max-width: 768px) {
  .secondBanner .container {
    max-width: 90% !important;
  }
  .secondBanner .container .row > * {
    width: 45vw !important;
  }

  .secondBanner h3 {
    font-size: 1.3rem;
    width: 70vw;
  }
  .secondBanner p {
    font-size: 0.9rem;
    width: 60vw;
    letter-spacing: 0.05rem;
  }

  .secondBanner .call-to-action a.get-a-quote,
  .banner-section2 .call-to-action a.get-a-quote {
    font-size: 2vw;
    padding: 0.5vw 2.5vw;
  }
}

@media screen and (max-width: 568px) {
  .secondBanner .container {
    max-width: 98% !important;
  }
  .secondBanner h3 {
    font-size: 3.5vw;
    width: 69vw;
    margin-right: 4vw;
  }

  .secondBanner p {
    font-size: 3vw;
  }

  .secondBanner .call-to-action {
    margin: 5vw 0 4vw 6vw;
  }

  .secondBanner p {
    width: 74vw !important;
  }
}
@media screen and (max-width: 430px) {
  .secondBanner .container {
    max-width: 93% !important;
  }
  .secondBanner h3 {
    font-size: 4.5vw;
    width: 95vw !important;
    margin-top: 2vw;
  }

  .secondBanner p {
    font-size: 3.2vw;
    width: 90vw !important;
  }
  .secondBanner .call-to-action {
    margin: 25vw 2vw 0 0;
  }
  .secondBanner .call-to-action a.get-a-quote {
    font-size: 3.4vw;
    padding: 1.3vw 4.6vw !important;
    margin-top: -2% !important;
  }
}
.ourvnew h6 {
  font-size: 1.3vw;
}
@media (max-width: 991px) {
  .ourvnew {
    padding: 3vw 0.3vw !important;
  }
  .ourvnew h2 {
    font-size: 3.1vw;
    margin: 0 1vw;
  }
  .ourvnew h6 {
    font-size: 1.45vw;
  }
}
@media (max-width: 768px) {
  .ourvnew h6 {
    font-size: 1.8vw;
    margin: 1vw 6vw !important;
  }
}

@media (max-width: 575px) {
  .ourvnew h2 {
    font-size: 3.9vw;
  }
  .ourvnew h6 {
    font-size: 2.1vw;
    margin-bottom: 10vw !important;
  }
  .numberTitle {
    font-size: 4vw !important;
  }
  .ourvnew .content img {
    width: 9vw !important;
  }
}

@media (max-width: 430px) {
  .ourvnew h2 {
    font-size: 4.7vw !important;
  }
  .ourvnew h6 {
    font-size: 2.7vw !important;
    line-height: 3.5vw;
  }

  .numberTitle {
    font-size: 4vw !important;
  }
  .ourvnew .content img {
    width: 9vw !important;
  }
}
