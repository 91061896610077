.blogHeader {
  background: url(../../assets/img/bh-professional-garden-maintenance-north-vancouver.jpg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 25vw;
}

.blogHeader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vw;
  background: rgba(0, 0, 0, 0.68); 
  z-index: 1;
} 

.blogMain > h2 {
  margin: 4vw 6vw;
  text-align: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}
.blogMain > p {
  margin: 4vw;
  text-align: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}

.blogMain hr {
  margin: 0 17vw;
  border-top: 2px solid;
}

.mainheading {
  padding: 1vw 0vw;
}

.blog a {
  color: #00ab6b;
  text-decoration: none;
}

.blogMain > section {
  padding-bottom: 1vw;
}
.featured-posts {
  margin-top: 4vw;
}

.cardBlog-columns.listfeaturedtag {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.cardBlog-columns {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 3vw;
  -moz-column-gap: 3vw;
  column-gap: 3vw;
}
.cardBlog-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5vw;
}

.cardBlog-columns .cardBlog {
  margin-bottom: 3vw !important;
}
.listfeaturedtag .wrapthumbnail {
  height: 18vw;
  flex: 0 0 auto;
}
.listfeaturedtag .cardBlog {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px !important;
  height: 18vw;
  padding-left: 0;
  margin-bottom: 1vw;
}
.listfeaturedtag .thumbnail {
  background-size: cover;
  height: 100%;
  display: block;
  background-position: 38% 22% !important;
  background-origin: border-box !important;
  border-top-left-radius: 2px;
}
.listfeaturedtag .cardBlog-block {
  padding-left: 0;
}
.listfeaturedtag h2.cardBlog-title,
.listrecent h2.cardBlog-title {
  font-size: 2.2vw;
  font-weight: 700;
  line-height: 1.25;
  color: var(--black-color);
}
.cardBlog a {
  text-decoration: none !important;
}
.listfeaturedtag h2.cardBlog-title a,
.listrecent h2.cardBlog-title a {
  color: var(--black-color) !important;
}
.listfeaturedtag h2.cardBlog-title a:hover,
.listrecent h2.cardBlog-title a:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.listfeaturedtag h4.cardBlog-text,
.listrecent h4.cardBlog-text {
  color: rgba(0, 0, 0, 0.44);
  font-size: 1vw;
  line-height: 1.4vw;
  font-weight: 400;
}
.listfeaturedtag .wrapfooter {
  position: absolute;
  bottom: 2vw;
  font-size: 2vw;
  display: block;
  width: 75%;
}
.listrecent .wrapfooter {
  font-size: 1vw;
  margin-top: 1.5vw;
}
.author-thumb {
  width: 2.8vw;
  height: 2.8vw;
  float: left;
  margin-right: 1vw;
  border-radius: 100%;
}
.author-meta {
  flex: 1 1 auto;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.post-date {
  font-size: 0.9vw;
}
.post-name {
  font-size: 1vw;
  color: var(--black-color);
}
span.post-name a,
span.post-read-more a:hover {
  color: rgba(0, 0, 0, 0.8);
}

.cardBlog {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}

.tagColor {
  position: absolute;
  top: 50%;
  right: 5%;
  width: 8vw;
  text-decoration: none;
  margin: 1vw 0;
  max-width: 8vw;
  align-items: center;
  padding: 0.2vw 0;
  justify-content: center;
  font-size: 1vw;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  color: #fff;
  border-radius: 3px;
}
.tagColor1 {
  position: absolute;
  bottom: 0%;
  right: 5%;
  width: 8vw;
  text-decoration: none;
  margin: 1vw 0;
  max-width: 8vw;
  align-items: center;
  padding: 0.2vw 0;
  justify-content: center;
  font-size: 1vw;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  color: #fff;
  border-radius: 3px;
}
.c1 {
  background: #36b37e;
}

.c2 {
  background: #ffab00;
}

.c3 {
  background: #ff5630;
}

.c4 {
  background: #61078a;
}

.cardBlog-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  margin: 5vw 0;
}
.blogMain .container {
  max-width: 100%;
  padding: 0 6vw !important;
}

/* coming soon */
.blogMain h3 {
  font-size: 4vw;
  text-align: center;
  margin: 7vw 0;
}
.beeGif {
  width: 30%;
  margin-bottom: 10vw !important;
}
.cardBlog .pbb {
  padding-bottom: 2vw !important;
}
@media screen and (max-width: 991px) {
  .blogMain .container {
    padding: 0 4vw !important;
  }
  
  /* coming soon */
  .blogMain h3 {
    font-size: 5vw;
    text-align: center;
    margin: 7vw 0;
  }
  .beeGif {
    width: 40%;
    margin-bottom: 19vw !important;
  }
  .cardBlog-title {
    font-size: 2.4vw !important;
  }
  .tagColor,
  .tagColor1 {
    width: 7.6vw;
    max-width: 9vw;
    font-size: 1.1vw;
    right:2%;
    top: 63%;
  }
  .tagColor1{
    top:86% !important;
  }
  .listfeaturedtag .wrapfooter {
    bottom: 1vw;
  }
}

@media (max-width: 575px) {
  .blogHeader, .blogHeader::before {
    height: 30vw;
  }

  .blogMain .container {
    padding: 0 3vw !important;
  }
  .cardBlog .mll {
    margin-left: -3vw;
  }
  .cardBlog .mrr {
    margin-left: 2vw;
  }
  .listfeaturedtag .cardBlog {
    height: 24vw;
  }
  .listfeaturedtag .wrapthumbnail {
    height: 23.7vw;
  }

  .post-name {
    font-size: 1.5vw;
  }
  .cardBlog-title {
    font-size: 2.8vw !important;
  }
  .cardBlog-columns {
    -webkit-column-gap: 3vw;
    -moz-column-gap: 3vw;
    column-gap: 3vw;
  }
 
  .tagColor1{
  top:89% !important;
  }
}

