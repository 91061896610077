.pavingstones {
  background: url("../../assets/img/garden-paving-design-north-vancouver.jpeg")
    center 30%;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.savingbees {
  background: url("../../assets/img/bee-harmony-gardens.jpeg") center 0%;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.chafer {
  background: url("../../assets/img/chafer-beetle-lawn-solutions.jpg") center
    30%;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.lawnfertilizer {
  background: url("../../assets/img/lavender-plants-blooming-vancouver.jpg")
    center 30%;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.seasonalmaintenance {
  background: url("../../assets/img/bh-family-lawn-care-services-north-vancouver.jpg")
    center 30%;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.wateringlawn {
  background: url("../../assets/img/efficient-lawn-watering.jpg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.pollinators {
  background: url("../../assets/img/lavender-haven.jpeg") fixed no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.fallcleanup {
  background: url("../../assets/img/golden-fall-landscapes.jpeg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.blackberry {
  background: url("../../assets/img/wild-berry-chronicles.jpg") fixed no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.perennials {
  background: url("../../assets/img/perennial-garden-prep-vancouver.jpg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.lateFallGardening {
  background: url("../../assets/img/winter-blooming-snowdrops-vancouver.jpg")
    fixed no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.soilAndMulch {
  background: url("../../assets/img/mulch-soil-preparation.png") fixed no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.snowIceManagement {
  background: url("../../assets/img/vancouver-winter-driveway-snow.jpg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.replacingRetainingWall {
  background: url("../../assets/img/allan-block-stone-retaining-wall-design.jpg")
    fixed no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.expertWalkway {
  background: url("../../assets/img/finished-patio-pavers-pathway.jpeg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.springLawn {
  background: url("../../assets/img/lawn-mowing-service-residential-yard.jpeg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.redThread {
  background: url("../../assets/img/lawn-damage-patchy-grass.jpg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.sustainableLand {
  background: url("../../assets/img/landscaped-lawn-with-hedge.jpg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cedarTimber {
  background: url("../../assets/img/wooden-raised-garden-bed-construction.jpg") fixed
    no-repeat;
  background-position: 20% center; /* Adjust this value to move the image horizontally */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.backPosts {
  position: fixed;
  top: 20%;
  left: 2%;
  text-decoration: none;
  color: #fff !important;
  font-weight: bold;
  font-size: 1.6vw !important;
}

.backPosts i {
  padding-right: 1rem;
  font-size: 2rem !important;
  vertical-align: middle;
}
/* End Of backToAllPosts */

.sectionBlogpost header {
  text-align: center;
  padding: 0 2em;
}

.sectionBlogpost h1 {
  font-family: "Old Standard TT", serif;
  color: var(--yellow-color) !important;
  text-transform: uppercase;
  padding-top: 12rem;
}

.sectionBlogpost .nth-line-1,
.sectionBlogpost .nth-line-2 {
  line-height: 1;
}

.sectionBlogpost .nth-line-1 {
  position: relative;
  font-size: 6.2vw;
  line-height: 8vw;
  margin-bottom: 2rem;
}

.sectionBlogpost .nth-line-2 {
  font-size: 3vw;
  margin-bottom: 3rem;
}

.sectionBlogpost article {
  color: #fff;
  font-size: 1.56vw;
  word-spacing: 0.2rem;
  max-width: 66em;
  margin-left: 40%;
  background: rgba(0, 0, 0, 0.8);
}

.sectionBlogpost .lead {
  color: #fff;
  font-size: 2.5vw;
  max-width: 20em;
  margin: 0 auto;
  text-align: center;
}

.sectionBlogpost hr {
  border: none;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/5175/hr-01.png")
    no-repeat center;
  height: 40px;
  margin-top: 1rem;
}
.sectionBlogpost header h3 {
  font-size: 3vw;
  text-align: left;
  text-transform: uppercase;
}

.sectionBlogpost section {
  -moz-column-width: 14em;
  column-width: 14em;
  -moz-column-gap: 1.33em;
  column-gap: 1.33em;
  padding: 0 1em;
  padding: 3rem 3rem;
}

.sectionBlogpost section p:first-of-type:first-letter {
  color: var(--yellow-color);
  float: left;
  font-size: 8em;
  line-height: 1;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  margin-right: 0.1em;
}

.sectionBlogpost section p span {
  color: var(--yellow-color);
  line-height: 1;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  font-size: 1.5vw;
}
.sectionBlogpost img {
  max-width: 100%;
  margin-bottom: 1.33em;
  vertical-align: middle;
  width: 100% !important;
}

.sectionBlogpost blockquote {
  border-left: 0.2em solid #fcf704;
  font-size: 1.6vw;
  padding: 0.1em 0.5em;
  line-height: 1.5;
  margin-left: 0;
  margin-right: 0;
  quotes: "“" "”" "‘" "’";
  position: absolute;
  right: 100%;
  top: 20%;
  width: 9em;
  background: rgba(0, 0, 0, 0.8);
}
.sectionBlogpost blockquote::before,
blockquote::after {
  color: var(--yellow-color);
  font-size: 1.25em;
  line-height: 0;
}
.sectionBlogpost blockquote::before {
  content: open-quote;
}
.sectionBlogpost blockquote::after {
  content: close-quote;
}
.sectionBlogpost .imgblog {
  width: 95% !important;
  margin: 0;
  padding: 5% 0;
}
.sectionBlogpost iframe {
  width: 95%;
  margin-left: 2%;
}

@media (max-width: 991px) {
  .sectionBlogpost {
    background-attachment: scroll !important;
    z-index: auto !important;
  }

  .sectionBlogpost .post {
    padding: 5vw;
  }
  .sectionBlogpost section p span {
    font-size: 2.3vw;
  }
  .sectionBlogpost article {
    font-size: 2.8vw;
    margin-top: 1vw;
    margin-left: 0;
    margin-bottom: -4vw;
    background: rgba(0, 0, 0, 0.8);
  }
  .sectionBlogpost .nth-line-1 {
    font-size: 10vw !important;
  }
  .sectionBlogpost .nth-line-2 {
    font-size: 5vw;
  }
  .sectionBlogpost hr {
    margin: 1rem 0 -0.7rem 0;
  }

  .backPosts {
    position: absolute !important;
    top: 12%;
    left: 1%;
    font-size: 2.9vw !important;
    color: var(--white-color) !important;
  }

  .backPosts i {
    font-size: 4vw !important;
    margin-right: -1vw;
  }

  .sectionBlogpost section p:first-of-type:first-letter {
    font-size: 10vw !important;
  }
}

@media (max-width: 768px) {
  .sectionBlogpost section {
    -moz-column-width: 10em;
    column-width: 10em;
  }
  .sectionBlogpost article {
    margin-left: 0%;
  }
  .sectionBlogpost section p span {
    font-size: 3vw !important;
  }
  .sectionBlogpost article {
    font-size: 3.2vw;
  }
  .sectionBlogpost .nth-line-1 {
    font-size: 11vw !important;
    line-height: 12vw !important;
  }
  .sectionBlogpost .nth-line-2 {
    font-size: 4.5vw;
    line-height: 6.5vw;
    margin-top: -2vw;
  }
  .sectionBlogpost .lead {
    color: #fff;
    font-size: 3.5vw;
  }

  .backPosts {
    top: 12%;
    left: 1%;
    font-size: 4.5vw !important;
    z-index: 1000;
  }

  .backPosts i {
    font-size: 5vw !important;
    margin-right: -1vw;
    z-index: 1000;
  }
}

@media (max-width: 430px) {
  .sectionBlogpost section {
    padding: 3rem 1.5rem 3rem 2rem;
  }

  .sectionBlogpost header {
    text-align: center;
    padding: 0 1em;
  }

  .sectionBlogpost section p span {
    font-size: 4.3vw !important;
  }

  .sectionBlogpost article {
    font-size: 5.3vw !important;
  }

  .sectionBlogpost .nth-line-1 {
    font-size: 11.5vw !important;
    line-height: 13vw !important;
  }

  .sectionBlogpost .nth-line-2 {
    font-size: 6vw !important;
    line-height: 7.5vw !important;
  }

  .sectionBlogpost .lead {
    font-size: 4vw !important;
    margin-top: -2vw;
  }

  .sectionBlogpost .imgblog {
    margin: 0 1%;
  }

  .backPosts {
    left: 2%;
    top: 12% !important;
    font-size: 6vw !important;
  }

  .backPosts i {
    font-size: 7vw !important;
    margin-right: -2vw;
  }
}

@media (max-width: 390px) {
  .sectionBlogpost article {
    margin-top: -8vw;
  }
}
