
.boxelectric::before{
  border:none !important;
}

.box .boxelectric:hover {
  background: none !important;
  border-color: #fff;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18) !important;

}
.box7 {
  background: url(../../assets/img/stihl-mower1.jpg)
}
.box8 {
  background: url(../../assets/img/stihl-blower1.jpg)
}
.box9 {
  background: url(../../assets/img/stihl-trimmer1.jpg)
}