.wrapper {
  max-width: 100vw !important;
}

.aboutUsHeader {
  background: url(../../assets/img/bh-professional-garden-maintenance-north-vancouver.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 25vw;
}

.aboutUsHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vw;
  background: rgba(0, 0, 0, 0.68);
  z-index: 1;
}

#company {
  padding: 3vw 5vw;
}

#company .heading {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15vw;
  text-align: center;
  max-width: 100%;
}

#company .heading h2 {
  font-size: 3vw !important;
  color: var(--black-color) !important;
}

.heading .sub {
  position: absolute;
  top: 5.2vw;
  left: 43%;
  color: #757575;
  font-size: 3vw;
}

.heading .sub:after {
  bottom: 0;
  content: "";
  position: absolute;
  width: 10vw;
  height: 2px;
  background: #b9a709;
}

.heading .subtle-text {
  position: absolute !important;
  top: 7.5vw !important;
  left: 0;
  right: 0;
  text-transform: uppercase;
  font-size: 10vw !important;
  color: rgba(0, 0, 0, 0.07);
  font-weight: 900;
  z-index: -100;
}

.heading-colored {
  box-shadow: 2.5vw -1vw #b9a709;
  color: #b9a709;
  font-size: 2.5vw;
  letter-spacing: 0.3rem;
  padding-top: 0.8vw;
}

.heading-colored-mirror {
  box-shadow: -5.5vw -1vw #b9a709;
  color: #b9a709;
  font-size: 2.5vw;
  letter-spacing: 0.3rem;
  padding-top: 0.8vw;
  padding-left: 1vw;
}

.heading-colored-text {
  font-size: 1.3vw;
  letter-spacing: 0.02rem;
  font-family: "din-light";
  text-align: justify;
}

.cl {
  background-color: rgba(0, 0, 0, 0.02);
  height: fit-content;
}

.img-shadow {
  position: relative;
  width: 80%;
}

.fl {
  filter: drop-shadow(40px 10px 4px #b5b5b7);
  float: left;
  max-width: 100%;
}

.fr {
  filter: drop-shadow(-40px 10px 4px #b5b5b7);
  /* Changed values here */
  max-width: 60%;
  float: right;
  margin-right: 6vw;
}

.founder-sub {
  position: absolute;
  top: 27%;
  left: 43%;
  color: #757575;
  font-size: 3vw;
}

.founder-sub {
  bottom: 0;
  content: "";
  position: absolute;
  width: 5rem;
  height: 2px;
  background: #b9a709;
}

.aboutUsMain > h3 {
  margin: 5rem;
  text-align: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}

::-webkit-selection {
  color: #fff;
  background: var(--yellow-color);
}

::-moz-selection {
  color: #fff;
  background: var(--yellow-color);
}

::selection {
  color: #fff;
  background: var(--yellow-color);
}

.aboutMain p {
  font-size: 1.2rem;
}

.mt {
  margin-top: -11% !important;
}

.row-pb-md {
  padding-bottom: 3vw !important;
}

.row-pb-sm {
  padding-bottom: 3vw !important;
}

.sideWrittenParts {
  position: relative;
  background-image: linear-gradient(
    to top,
    rgba(2, 1, 25, 0.92) 30%,
    rgba(42, 41, 61, 0.92) 30%,
    var(--yellow-color) 100%
  );
}

.lastTitle {
  font-size: 3rem !important;
  margin-bottom: 5rem;
  color: #eae6e6;
}

.last {
  font-size: 1.4rem !important;
  line-height: 2.5rem;
  letter-spacing: 0.2rem;
  max-width: 70vw !important;
  margin-left: 1rem;
  color: #eae6e6;
  font-style: italic;
}

.sideWritten {
  position: absolute;
  top: 5%;
  left: 3%;
  color: #eae6e6;
  font-size: 1rem !important;
}

.verticalWritten {
  position: absolute;
  bottom: 0%;
  left: 65%;
  margin: 50px -30px;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 100px;
  text-align: center;
  color: #eae6e6;
  font-size: 1rem !important;
}

.sideWritten:before {
  display: inline-block;
  margin: 0 5px 3px 0;
  height: 3px;
  content: " ";
  text-shadow: none;
  background-color: var(--yellow-color);
  width: 30px;
}

.container:before,
.container:after {
  content: " ";
  display: table;
}

@media (min-width: 1400px) {
  #company .container {
    max-width: 100%;
  }
}
@media screen and (max-width: 991px) {
  #company {
    padding: 5vw 3vw 3vw 3vw;
  }
  #company .container,
  #company .container-md,
  #company .container-sm {
    max-width: 99% !important;
  }

  .fl {
    max-width: 100%;
  }

  .fr {
    max-width: 70%;
  }

  #company .heading h2 {
    font-size: 3.5vw !important;
  }

  .heading .sub:after,
  .heading .subtle-text:after {
    margin-bottom: 2.5vw;
  }

  .heading .sub,
  .heading .subtle-text {
    font-size: 15vw !important;
    margin-top: 2vw;
  }

  .heading-colored {
    font-size: 3vw;
    letter-spacing: 0.3rem;
  }

  .heading-colored-mirror {
    font-size: 3vw;
    letter-spacing: 0.3rem;
  }

  .heading-colored-text {
    font-size: 1.5vw;
    letter-spacing: 0.03rem;
  }
}

@media screen and (max-width: 575px) {
  .aboutUsHeader,
  .aboutUsHeader::before {
    height: 30vw;
  }

  .container-sm {
    max-width: 75% !important;
  }

  #company .heading h2 {
    font-size: 4.2vw !important;
  }

  .hcresize {
    padding: 0 6% !important;
  }

  .heading-colored {
    font-size: 4.2vw;
    letter-spacing: 0.3rem;
    padding-top: 0.8vw;
  }

  .heading-colored-mirror {
    font-size: 4.2vw;
    letter-spacing: 0.3rem;
    padding-top: 0.8vw;
    padding-left: 1vw;
  }

  .heading-colored-text {
    font-size: 3.2vw;
    letter-spacing: 0.01rem;
  }

  .fl {
    width: 70%;
    margin: 0 12vw 7vw 12vw;
  }

  .fr {
    width: 60%;
    margin: 0 16vw 7vw 16vw;
  }

  .heading .sub {
    position: absolute;
    top: 7.5vw !important;
  }

  .heading .subtle-text {
    margin-top: 3vw;
    font-size: 14vw !important;
  }
}
@media screen and (max-width: 430px) {
  #company .heading h2 {
    font-size: 4.2vw !important;
  }

  .heading-colored {
    font-size: 4.2vw;
  }

  .heading-colored-mirror {
    font-size: 4.2vw;
  }

  .heading-colored-text {
    font-size: 3.2vw;
  }

  .heading .subtle-text {
    font-size: 14vw !important;
  }
}

/*--------------------------------------------------------------
# BoxChangingColor
--------------------------------------------------------------*/

.box {
  background: url(../../assets/img/blurImage.png);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  height: 17vw;
  width: 100vw !important;
}

.box h2 {
  color: #fff;
  font-family: tahoma;
  font-size: 2.6vw;
  font-weight: 100;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 100%;
  top: 38%;
}

.box h2 span {
  font-size: 2.1vw;
  margin-left: 14vw;
}

.box .message {
  background-color: yellow;
  color: #333;
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
  padding-left: 0.5rem;
  top: 0.2rem;
  left: 64.5vw;
  animation: openclose 5s ease-in-out infinite;
}

.box .word1,
.word2,
.word3 {
  font-family: tahoma;
}

@keyframes openclose {
  0% {
    top: 0;
    width: 0;
  }

  5% {
    width: 0;
  }

  15% {
    width: 12vw;
  }

  30% {
    top: 0.1vw;
    width: 12vw;
  }

  33% {
    top: 0.1vw;
    width: 0;
  }

  35% {
    top: 0.2vw;
    width: 0;
  }

  38% {
    top: -3.8vw;
  }

  48% {
    top: -3.8vw;
    width: 10vw;
  }

  62% {
    top: -3.8vw;
    width: 10vw;
  }

  66% {
    top: -3.8vw;
    width: 0;
    text-indent: 0;
  }

  71% {
    top: -7.7vw;
    width: 0;
    text-indent: 0.31rem;
  }

  86% {
    top: -7.7vw;
    width: 16.2vw;
  }

  95% {
    top: -7.7vw;
    width: 16.2vw;
  }

  98% {
    top: -7.7vw;
    width: 0;
    text-indent: 0.31rem;
  }

  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}

@media screen and (max-width: 991px) {
  .box h2 span {
    margin-left: 10vw;
  }
}

@media screen and (max-width: 991px) {
  .box h2 span {
    margin-left: 9vw;
    font-size: 2.7vw;
  }

  .box .message {
    left: 79vw;
  }
}

/*--------------------------------------------------------------
# Our Services 
--------------------------------------------------------------*/
.hexServices {
  margin: 3vw 0 4vw 4vw;
  max-width: 90vw;
  width: 90vw;
  overflow: hidden;
}

.hexServices h2 {
  margin: 4vw !important;
}

.hex-menu {
  flex: 0 0 auto;
  width: 100%;
}

.xpt-center {
  text-align: center;
}

.xpt-hex {
  position: relative;
  z-index: 0;
  color: var(--black-color);
  display: inline-block;
  margin: 9vw auto;
  letter-spacing: 0.1rem;
  width: 29vw;
  height: 15.3vw;
  text-align: justify;
}

.xpt-ihex {
  position: absolute;
  z-index: 1;
  left: 3vw;
  right: 0.75vw;
  top: 0.38vw;
  bottom: 0.38vw;
}

.xpt-ihex:after {
  content: "";
  position: absolute;
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  transform: rotate(60deg);
}

.xpt-ihex:before {
  content: "";
  position: absolute;
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.xpt-ihex,
.xpt-ihex:after,
.xpt-ihex:before {
  /* background: linear-gradient(to right, #989104  0%, #938a06   100%); */
  background: linear-gradient(
    114.3deg,
    rgb(101, 104, 102) 0.2%,
    rgb(112, 119, 115) 68.5%
  );
}

.xpt-ihex:after,
.xpt-ihex:before {
  width: 100%;
  height: 100%;
}

.xpt-box-inr {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.xpt-title {
  font-size: 1.1vw;
  text-transform: uppercase;
  margin-top: -13% !important;
  margin-bottom: 3%;
  border: 1px solid #fff;
  color: var(--black-color);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 2%;
}

.hex__details {
  padding: 0 2%;
}

.hex__details ul {
  list-style-type: none;
  max-width: 99%;
  display: block;
  padding: 0 1vw;
  letter-spacing: 0.03rem;
}

.hex__details ul li {
  text-align: center;
  font-size: 0.65vw;
  padding: 0.2vw 0;
  letter-spacing: 0.03rem !important;
  text-transform: uppercase;
  font-family: "din-light";
  color: #fff;
}

.hex__details ul li:not(:last-child) {
  padding: 0.35vw 0;
  letter-spacing: 0.09rem;
  border-bottom: 1px solid #fff;
  margin: 0;
}

@media screen and (max-width: 991px) {
  .hexServices {
    overflow: hidden !important;
  }
  .xpt-hex {
    width: calc(64.6% - 20vw) !important;
    margin: 17vw 2.5vw !important;
  }

  .xpt-ihex {
    top: -5vw !important;
  }

  .xpt-title {
    font-size: 1.6vw;
    letter-spacing: 0.04rem;
  }

  .hex__details ul li {
    font-size: 0.95vw;
  }
}
@media screen and (max-width: 768px) {
  .hex__details ul li {
    font-size: 0.95vw;
  }
}
@media screen and (max-width: 575px) {
  .hex__details ul li {
    font-size: 0.93vw;
  }
}

@media screen and (max-width: 430px) {
  .xpt-hex {
    width: calc(82.4% - 15%) !important;
    margin: 25vw 3vw !important;
  }

  .xpt-ihex {
    top: -7.3vw !important;
    left: 4.5vw !important;
    right: 4.5vw !important;
    bottom: -7.3vw !important;
  }

  .xpt-title {
    font-size: 2.7vw;
    letter-spacing: 0.01rem;
  }

  .hex__details ul li {
    font-size: 1.48vw;
  }
}

/*--------------------------------------------------------------
# PARTNERS
--------------------------------------------------------------*/
.aboutUsMain .partners-section-heading {
  padding: 1rem;
}

.aboutUsMain .partners-section-heading h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--yellow-color);
  letter-spacing: 2px;
  padding-bottom: 0;
}

.aboutUsMain .partners-section-subtitle {
  font-family: "din-light", sans-serif, Arial, Helvetica;
  font-size: 1.3rem;
  font-weight: 300;
  color: #9c9c9c;
  margin: 1.5rem auto;
  width: 50%;
}

.aboutUsMain .partners img:hover {
  filter: none;
  transform: scale(1.2);
}

.aboutUsMain .partner1 {
  width: 55%;
}

.aboutUsMain .partner2 {
  width: 55%;
}

.aboutUsMain .partner3 {
  width: 40%;
}

.aboutUsMain .partner4 {
  width: 68%;
}

.aboutUsMain .partner5 {
  width: 70%;
}

.aboutUsMain .partner6 {
  width: 80%;
}

.aboutUsMain .partner7 {
  width: 55%;
}

.aboutUsMain .partner8 {
  width: 100%;
}

@media (max-width: 991px) {
  .aboutUsMain .partners-section-heading {
    padding: 0 !important;
  }
  .aboutUsMain .col-1-5,
  .aboutUsMain .col-1-5 {
    width: 12.5%;
    padding: 0;
  }
}

@media (max-width: 430px) {
  .aboutUsMain .partners-section-heading {
    padding: 0;
  }

  .aboutUsMain .partners .section-header {
    margin: 2vw !important;
  }

  .aboutUsMain .partner1 {
    width: 70% !important;
  }
  .aboutUsMain .partner2 {
    width: 60% !important;
  }
  .aboutUsMain .partner3 {
    width: 40% !important;
  }
  .aboutUsMain .partner4 {
    width: 80% !important;
  }
  .aboutUsMain .partner5 {
    width: 85% !important;
  }
  .aboutUsMain .partner6 {
    width: 100% !important;
  }
  .aboutUsMain .partner7 {
    width: 65% !important;
  }
  .aboutUsMain .partner8 {
    width: 100% !important;
  }
}

/*--------------------------------------------------------------
# Why Choose Us
--------------------------------------------------------------*/

#whyChooseUs h2 {
  margin-bottom: 4vw;
}

#whyChooseUs .card-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5vw;
  padding: 0px;
}

#whyChooseUs .card {
  width: calc(33.33% - 2vw);
  border-radius: 8px;
  padding: 2vw;
  box-sizing: border-box;
  border: none !important;
}

.chooseUsIcons {
  font-size: 3.5vw;
  background: #056201 !important;
  color: #fff;
  border-radius: 0.7vw;
  width: 4.5vw;
  height: 4.5vw;
}

#whyChooseUs h4 {
  font-size: 1.8vw;
  margin: 1vw 0;
}

#whyChooseUs p {
  font-size: 1.2vw;
  letter-spacing: 0.02rem;
  font-family: "din-light";
}

.checkmark {
  box-shadow: 1.5vw 0.8vw 0.8vw 0.9vw rgba(37, 37, 39, 0.3);
}

@media (max-width: 991px) {
  #whyChooseUs h4 {
    font-size: 2.5vw;
  }

  .chooseUsIcons {
    font-size: 3.5vw;
    width: 5vw;
    height: 5vw;
  }

  #whyChooseUs p {
    font-size: 1.8vw;
  }

  #whyChooseUs .card {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  #whyChooseUs .card {
    width: calc(50% - 20px);
  }

  #whyChooseUs .card {
    padding: 8px;
  }

  #whyChooseUs p {
    font-size: 1.4vw;
  }
}

@media (max-width: 430px) {
  #whyChooseUs .card {
    width: calc(50% - 20px);
    padding: 8px;
  }

  .chooseUsIcons {
    font-size: 4.5vw;
    width: 6vw;
    height: 6vw;
    padding-bottom: 0.5vw;
  }

  #whyChooseUs h4 {
    font-size: 4.3vw;
  }

  #whyChooseUs p {
    font-size: 3.5vw;
  }
}

/*--------------------------------------------------------------
# QuoteLine
--------------------------------------------------------------*/
#quoteLine {
  background: url(../../assets/img/professional-gardener-at-work.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  width: 100%;
}

.quoteLine,
.quoteLine .content {
  color: #fff;
  text-align: center;
  padding: 1vw 7vw;
}

.quoteLine .content h3 {
  font-weight: 400;
  font-size: 2vw;
  color: #fff;
  letter-spacing: 0.15vw;
  padding-bottom: 1vw;
  line-height: 3vw;
  text-transform: uppercase;
}

.buttonQuoteLine {
  background-color: #066223;
  display: inline-block;
  color: var(--white-color);
  padding: 0.6vw 1.7vw;
  font-size: 1vw;
  text-decoration: none;
  border-radius: var(--button-border);
  transition: background-color 0.3s;
}

@media (max-width: 991px) {
  .quoteLine .content h3 {
    font-size: 2.2vw;
  }

  .buttonQuoteLine {
    padding: 0.6vw 2.5vw;
    font-size: 1.7vw;
  }
}

@media (max-width: 430px) {
  .quoteLine .content {
    color: #fff;
    text-align: center;
    padding: 3vw 0vw;
  }

  .quoteLine .content h3 {
    font-size: 3.5vw;
    line-height: 1.4;
  }

  .buttonQuoteLine {
    padding: 1.1vw 4vw;
    font-size: 3.3vw;
  }
}
