#home h1 {
  display: none !important;
}

.wrapper {
  max-width: 100vw;
  font-family: "din-condensed";
}

.bh30Logo {
  width: 10%;
  position: fixed;
  bottom: 2%;
  left: 0.2%;
  z-index: 1000;
}

.beeLogo {
  width: 6.5%;
  position: fixed;
  bottom: 1%;
  right: 0.2%;
  z-index: 1000;
}

.bh30Logo {
  width: 10%;
  position: fixed;
  bottom: 1%;
  left: 0.2%;
  z-index: 1000;
}

.slider-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.bg-fade {
  position: absolute;
  inset: 0;
  opacity: 0;
  animation-name: fade-zoom;
  animation-duration: 18s;
  animation-iteration-count: infinite;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
}

.bg-fade:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  bottom: 0;
  left: 0;
  background: url("../../assets/img/bg-grid.png");
}

.bg-fade:nth-child(1) {
  animation-delay: 0s;
}

.bg-fade:nth-child(2) {
  animation-delay: 6s;
}

.bg-fade:nth-child(3) {
  animation-delay: 12s;
}

@keyframes fade-zoom {
  0% {
    opacity: 0;
  }

  16.66% {
    opacity: 1;
  }

  33.33% {
    opacity: 1;
  }

  48.66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.bg-fade-wrapper:hover .bg-fade {
  animation-play-state: paused;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  padding: 0.6rem 1.4rem 0.3rem 1.4rem;
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid var(--white-color);
  font-size: 1.3vw;
  color: var(--white-color);
  text-transform: uppercase;
  filter: blur(40%);
  border-radius: var(--button-border);
  font-family: "din-condensed";
}

.slider-content .inner h2 {
  margin: 0 auto;
  color: var(--white-color);
  font-size: 3.5vw;
  width: 65vw !important;
  line-height: 3.8vw;
  text-transform: uppercase;
  letter-spacing: 0.7rem;
  margin: 0 auto 30px;
}

.slider-content section {
  position: absolute;
  bottom: 1.25rem;
  left: 1.25rem;
}

.slider-content section img {
  max-width: 2.5rem;
  height: 2.5rem;
  border: solid 2px rgba(255, 255, 255, 0.5);
  vertical-align: middle;
}

/*slider*/
.slide h2 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-1.25rem);
  transform: translateY(-1.25rem);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(1.25rem);
  transform: translateY(1.25rem);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(1.25rem);
  transform: translateY(1.25rem);
  opacity: 0;
}

@media (max-width: 991px) {
  .bg-fade:first-child {
    background-position: calc(5% - 20px) center !important;
    /* Adjust the value as needed */
  }

  .bg-fade:nth-child(2) {
    background-position: calc(60% - 20px) center !important;
    /* Adjust the value as needed */
  }

  .slider-content .inner {
    width: 100% !important;
  }

  .slider-content .inner h2 {
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 0.5rem;
    width: 90% !important;
  }
  .bh30Logo {
    width: 11.9% !important;
  }
  .beeLogo {
    width: 8% !important;
  }
}

@media (max-width: 768px) {
  .bg-fade:first-child {
    background-position: calc(0% - 20px) center !important;
    /* Adjust the value as needed */
  }

  .bg-fade:nth-child(2) {
    background-position: calc(55% - 20px) center !important;
    /* Adjust the value as needed */
  }

  .slider-content .inner h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0.2rem;
  }
.bh30Logo{
  width: 12.3% !important;
  bottom: 1.4%;

}
  .beeLogo {
    width: 9% !important;
    bottom: 1%;
  }
}
@media (max-width: 575px) {

  .bh30Logo {
    width: 18vw!important;
    bottom: 18vw;
    left: -1%;
  }
  .beeLogo {
    width: 12vw!important;
    bottom: 18vw;
    right: 0%;
  }
}

@media (max-width: 431px) {
  .bg-fade:first-child {
    background-position: calc(17% - 20px) center !important;
  }

  .bg-fade:nth-child(2) {
    background-position: calc(58% - 20px) center !important;
    /* Adjust the value as needed */
  }
  .bh30Logo {
    width: 20% !important;
    bottom: 17vw;
  }
  .beeLogo {
    width: 14% !important;
    bottom: 17vw;
  }
}


@media (max-width: 380px) {
  .bg-fade:first-child {
    background-position: calc(7% - 20px) center !important;
  }
}

/*--------------------------------------------------------------
# Partners
--------------------------------------------------------------*/
.col-1-5 {
  width: 12.5% !important;
}

.container-partners {
  margin-top: 1vw;
}

.partners {
  background: #f2f2f2;
  padding: 1.5vw 3vw 1.5vw 5vw;
  text-align: center;
}

.partners img {
  filter: grayscale(100);
  transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
}

.partners img:hover {
  filter: none;
  transform: scale(1.2);
}

.partner1 {
  width: 60% !important;
}

.partner2 {
  width: 55% !important;
}

.partner3 {
  width: 40% !important;
}

.partner4 {
  width: 68% !important;
}

.partner5 {
  width: 70% !important;
}

.partner6 {
  width: 80% !important;
}

.partner7 {
  width: 55% !important;
}

.partner8 {
  width: 100% !important;
}

@media (max-width: 991px) {
  .col-1-5,
  .col-1-5 {
    width: 12.5% !important;
    padding: 0 !important;
  }

  .partner1 {
    width: 72% !important;
  }

  .partner2 {
    width: 62% !important;
  }

  .partner3 {
    width: 42% !important;
  }

  .partner4 {
    width: 82% !important;
  }

  .partner5 {
    width: 87% !important;
  }

  .partner6 {
    width: 100% !important;
  }

  .partner7 {
    width: 65% !important;
    margin-left: 2.5vw;
  }

  .partner8 {
    width: 100% !important;
  }
}

@media (max-width: 430px) {
  .partners {
    background: #f2f2f2;
    padding: 1.5vw 2vw 1.5vw 4vw;
  }

  .partner1 {
    width: 75% !important;
  }

  .partner2 {
    width: 65% !important;
  }

  .partner3 {
    width: 45% !important;
  }

  .partner4 {
    width: 85% !important;
  }

  .partner5 {
    width: 90% !important;
  }

  .partner6 {
    width: 100% !important;
  }

  .partner7 {
    width: 70% !important;
  }

  .partner8 {
    width: 100% !important;
  }
}

/*--------------------------------------------------------------
--------------------------------------------------------------*/
.about .container {
  margin-bottom: 3vw !important;
  transition: all 0.8s ease;
}

.about .section-header {
  margin-top: -0.2vw !important;
}

.mb1 {
  margin-bottom: 7vw;
}

.about .section-header hr {
  margin: 0 8rem;
  display: block;
  border-top: 2px solid rgb(2, 2, 2);
}

.writtenContent {
  margin: 7vw 4vw 2vw 0vw !important;
  text-align: justify;
}

.corner-left h3 {
  position: absolute !important;
  margin: 23vw 0 !important;
  left: -16% !important;
  opacity: 1 !important;
  font-size: 2.8vw !important;
  color: var(--yellow-color) !important;
  transition: opacity 0.35s ease-in-out !important;
  -moz-transition: opacity 0.35s ease-in-out !important;
  -o-transition: opacity 0.35s ease-in-out !important;
  -webkit-transition: opacity 0.35s ease-in-out !important;
  transform-origin: top left !important;
  transform: translateY(100%) rotate(-90deg) !important;
  z-index: 1 !important;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.content {
  height: fit-content;
}

.corner-left {
  border-left: 2px solid rgb(191, 191, 191);
  position: relative;
  margin-left: 4vw !important;
}

.aboutSentence {
  font-size: 1.3vw;
  letter-spacing: 0.03rem;
  margin-left: 2vw;
}
.bhTeam{
  width: 90%;
float: right;}

@media (max-width: 991px) {
  .about .section-header {
    margin-top: -0.8vw !important;
  }

  .corner-left h3 {
    font-size: 4vw !important;
    margin-top: 55% !important;
    left: -11% !important;
  }

  .aboutSentence {
    font-size: 2.8vw;
  }
}

@media (max-width: 575px) {
  .about .section-header {
    margin-top: -0.8vw !important;
  }

  .about img {
    margin-bottom: 4vw;
    max-width: 100%;
  }

  .aboutSentence {
    font-size: 4vw;
  }
}

@media (max-width: 430px) {
  .aboutSentence {
    font-size: 4.5vw;
  }
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
#counts {
  background: url("../../assets/img/van1.jpg") center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  
}

#counts .container {
  margin-bottom: 4vw !important;
}

#counts::before {
  content: "";
  position: absolute;
  background: rgba(17, 17, 17, 0.4);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.count-box {
  padding: 1vw 0;
  width: 100%;
 margin-left: 5vw;
}

.counts .count-box i {
  display: block;
  font-size: 3vw;
  color: var(--yellow-color);
  float: left;
  margin: -1vw 0.5vw 0 0;
}

.counts .count-box span {
  font-size: 2.8vw;
  line-height: 2.3vw;
  color: var(--white-color);
  display: block;
  margin-left: 2.9vw;
}

.counts .count-box p {
  padding: 0.2vw 0 0 0;
  margin: 0 0 0 3vw;
  font-size: 1.9vw;
  color: var(--white-color);
  font-weight: bold;
}

.about .count-box a:hover {
  color: #477392;
}

@media (max-width: 991px) {
  #counts {
    background-attachment: scroll !important;
    z-index: auto !important;
  }

  .counts .row {
    margin-left: 3vw;
    padding-right: 1vw;
  }

  .counts .count-box i {
    font-size: 3.5vw;
  }

  .counts .count-box span {
    font-size: 3vw;
    margin-left: 3.3vw !important;
    line-height: 3vw;
  }

  .counts .count-box p {
    font-size: 2.7vw;
    margin: 0 0 0 3vw;
  }
}
@media (min-width: 3796px) {
  #counts .container {
    max-width: 90%;
  }
}


@media (max-width: 768px) {
  .counts .row {
    margin-left: 0.5rem;
  }

  .counts .count-box i {
    font-size: 4vw;
    padding-right: 0.1rem;
  }

  .counts .count-box span {
    font-size: 3.5vw;
    line-height: 3vw;
  }

  .counts .count-box p {
    font-size: 3vw;
    margin: 0 0 0 5vw;
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  #counts {
    background-attachment: fixed;
  }
}

@media (max-width: 430px) {
  #counts {
    background-attachment: scroll;
  }

  .counts .row {
    margin-left: 0.1rem;
  }

  .counts .count-box i {
    font-size: 4vw;
    padding-right: 0.1rem;
  }

  .counts .count-box span {
    font-size: 4.5vw;
    line-height: 3vw;
  }

  .counts .count-box p {
    font-size: 4.5vw;
    margin: 0 0 0 4vw;
    line-height: 5vw;
    width: 100% !important;
  }
}

/*--------------------------------------------------------------
# SERVICES
--------------------------------------------------------------*/
.services {
  height: 80% !important;
  margin-bottom: 1.5rem !important;
}

.services p {
  font-size: 1.5vw;
  margin: 0 auto 0 auto;
  text-align: center;
  width: 70vw;
  letter-spacing: 0.1rem;
  padding-bottom: 4vw;
  font-family: "din-condensed";
}

.servicesCard {
  position: relative;
  height: 16vw;
  width: 100%;
  margin: 0.8rem 0;
  transition: ease all 2.3s;
  perspective: 1200px;
}

.services .row {
  align-items: center;
  width: 85%;
  margin: 0 auto;
}

.servicesCard:hover .cover {
  transform: rotateX(0deg) rotateY(-180deg);
}

.servicesCard:hover .cover:before {
  transform: translateZ(30px);
}

.servicesCard:hover .cover:after {
  background-color: black;
}

.servicesCard:hover .cover h2 {
  transform: translateZ(100px);
}

.servicesCard .cover {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: ease all 2s;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.servicesCard .cover:before {
  content: "";
  position: absolute;
  border: 0.3rem solid;
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 1);
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  transition: ease all 2s;
  transform-style: preserve-3d;
  transform: translateZ(0px);
  z-index: 1000;
}

.servicesCard .cover:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
  transition: ease all 1.3s;
  background: rgba(0, 0, 0, 0.4);
}

.servicesCard .cover.item-a {
  background-image: url("../../assets/img/lawn-mowing-experts.jpg");
}

.servicesCard .cover.item-b {
  background-image: url("../../assets/img/retaining-wall-lighting.jpeg");
}

.servicesCard .cover.item-c {
  background-image: url("../../assets/img/modern-tiered-backyard-design.png");
}

.servicesCard .cover.item-d {
  background-image: url("../../assets/img/lawn-maintenance-fertilizer-spreader.jpg");
}

.servicesCard .cover.item-e {
  background-image: url("../../assets/img/softscape-hedge-landscape.jpg");
}

.servicesCard .cover.item-f {
  background-image: url("../../assets/img/snow-covered-stairs-walkway.jpeg");
}
.servicesCard .cover.item-g {
  background-image: url("../../assets/img/friendly-lawn-care-pros.jpg");
}

.servicesCard .cover h3 {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
  width: fit-content;
  font-size: 2.1vw;
  padding: 0 20px;
  font-weight: bold;
  color: white;
  transform-style: preserve-3d;
  transition: ease all 2s;
  z-index: 3;
  transform: translateZ(0px);
}

.servicesCard .card-back {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: auto;
  background: var(--black-color);
  transform-style: preserve-3d;
  transition: ease all 2s;
  transform: translateZ(-1px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.servicesCard .card-back ul {
  transform-style: preserve-3d;
  transition: ease transform 2s, ease background 0.5s;
  transform: translateZ(-1px) rotatey(-180deg);
  background: transparent;
  font-size: 0.85vw;
  letter-spacing: 0.015rem;
  color: white;
  width: 100%;
  height: 80%;
  outline: none;
  text-transform: uppercase;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }

  40% {
    --gradX: 100%;
    --gradY: 100%;
  }

  60% {
    --gradX: 50%;
    --gradY: 100%;
  }

  80% {
    --gradX: 0%;
    --gradY: 50%;
  }

  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}

@media (min-width: 3000px) {
  
    .services .col-lg-4{
      padding: .7rem 1.2rem;
    }
}

@media (max-width: 991px) {
  .services .row {
    width: 95%;
  }

  .servicesCard {
    height: 27vw;
    margin: 0.8rem 0;
  }

  .servicesCard .cover h3 {
    font-size: 3.2vw;
  }

  .services p {
    font-size: 2vw;
    width: 90%;
  }

  .servicesCard ul li {
    font-size: 1.36vw;
    padding: 0.1vw 0.1vw;
  }
}

@media (max-width: 575px) {
  .services .row {
    width: 70%;
  }

  .servicesCard .cover h3 {
    font-size: 5vw;
  }

  .services p {
    font-size: 3.7vw;
    width: 90%;
  }

  .servicesCard {
    height: 38vw;
  }

  .servicesCard ul li {
    font-size: 1.95vw;
    padding: 0.1vw 0;
  }
}

/*--------------------------------------------------------------
# Testimonials 
--------------------------------------------------------------*/
#testimonial {
  background: url(../../assets/img/professional-gardener-at-work.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

#testimonial .container .section-header {
  color: var(--white-color);
}

.testimonial-button {
  margin: 0 1vw;
  text-decoration: none;
  background-color: var(--yellow-color);
  color: var(--white-color);
  border-radius: var(--button-border);
  padding: 1vw;
  font-size: 1.1vw;
}

.shadow-effect {
  background: #efefef;
  padding: 2vw 1vw 3vw 1vw;
  width: auto;
  margin: auto;
  height: fit-content;
  border-radius: 4px;
}

.stars {
  color: #e9c70a;
  padding-bottom: 0.5vw;
  font-size: 1.2vw;
}

#customer-testimonoals {
  margin-top: 3%;
}

.testimonial-name {
  position: absolute;
  margin-top: -1.2vw;
  font-size: 1.3vw;
  left: 5vw;
  width: 20vw;
  background-color: var(--blue-color);
  padding: 1vw 1.9vw;
  border-radius: var(--button-border);
  text-align: center;
  color: #fff;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5);
}
.owl-carousel .owl-item {
  margin-bottom: 3vw;
}
.testimonial-name::before {
  content: "\2014\0020";
}

 .testimonial-description {
  font-size: 1.3vw;
  line-height: 1;
}

#customer-testimonoals .item {
  position: relative;
  text-align: center;
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
  width: 30vw;
}

#customer-testimonoals .owl-item.active.center .item {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.owl-carousel .owl-dots {
  display: none;
}

/* Responsive Media Queries */
@media (min-width: 3800px) {
  #testimonial .container{
    max-width: 90%;
  }
  #testimonial .container h2{
    margin-bottom: 2vw;
  }
  .stars {
    font-size: 1vw;
    padding-bottom: 0.8vw;
  }
.testimonial-button {
  padding: .7vw;
}

}
@media (min-width: 1400px) {
  q .testimonial-description {
    font-size: 1.6vw;
  }

}
@media (max-width: 991px) {
  #testimonial {
    padding-top: 7vw;
    height: 57vw !important;
    background-attachment: scroll;
  }

  #testimonial .container .section-header {
    margin-top:0vw;
  }

  .testimonial-button,
  .testimonial-name {
    font-size: 1.7vw;
  }

  .testimonial-description,
  #customer-testimonoals .item {
    font-size: 1.7vw;
    line-height: 1.2;
  }

  #customer-testimonoals .item {
    margin: auto;
  }
}

@media (max-width: 599px) {
  #testimonial {
    height: 75vw !important;
  }

  .testimonial-description {
    font-size: 3vw;
  }

  .testimonial-button,
  .testimonial-name {
    font-size: 2.5vw;
    width: 40vw;
  }

  .shadow-effect {
    height: fit-content;
    padding: 3vw 2vw;
    margin-top: 3vw;
  }

  #customer-testimonoals .item {
    width: 58vw;
  }

  .testimonial-button,
  .testimonial-name {
    width: 40vw;
    margin-left: 4vw;
  }
}

@media (max-width: 480px) {
  #testimonial {
    height: 35vh;
  }

  #testimonial .container .section-header {
    margin: 0;
    padding-bottom: 2vw;
  }
  #customer-testimonoals{
    margin-top: -6vw !important;
    height: fit-content !important;
  }
  #customer-testimonoals .item {
    width: 62vw;
    height: 50vw !important;
  }

  .testimonial-description {
    font-size: 3.5vw;
    line-height: 1.2;
  }
  .testimonial-name {
    font-size: 2.9vw;
}
  .testimonial-button {
    padding: 1.5vw;
  }

  .testimonial-button,
  .testimonial-name {
    margin-left: 5vw;
  }

.stars {
  padding-bottom: 0.9vw;
  font-size: 1.9vw;
}
}

@media (max-width: 375px) {
  #testimonial {
    height: 60vh;
  }
}

/*--------------------------------------------------------------
# ourValues
--------------------------------------------------------------*/
.ourValues {
  margin-bottom: 3vw !important;
  padding: 3vw 0;
}

.ourValues .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;
  margin-left: -2vw;
}

.ourValues .row {
  margin-top: -2vw;
}

.ourValues .icon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourValues .content img {
  margin-top: 5vw;
  margin-bottom: 0rem;
  width: 4.3vw !important;
}

.ourValues .content h3 {
  margin: 2vw 0;
  font-size: 1.6vw;
}

.ourValues .content p {
  text-align: center !important;
  font-size: 1.28vw;
  margin-bottom: 0.5rem;
}

.bgdark1 {
  background: #232325;
}

@media (max-width: 991px) {
  .ourValues .row {
    margin-top: -3vw;
  }
  .ourValues {
    margin-bottom: 3vw !important;
    padding: 3vw 6vw;
  }
  .ourValues .content h3 {
    font-size: 2.6vw;
  }
  .ourValues .content p {
    font-size: 2vw;
  }
  .ourValues .content img {
    width: 6.2vw !important;
  }
}

@media (max-width: 660px) {
  .ourValues .content h3 {
    font-size: 2.8vw;
  }
  .ourValues .content p {
    font-size: 2.3vw;
  }
}

@media (max-width: 575px) {
  .ourValues .content h3 {
    font-size: 1.4rem;
  }
  .ourValues .content p {
    font-size: 1rem;
  }
  .ourValues .content img {
    width: 11vw !important;
    margin-bottom: 2vw !important;
  }
  .ourValues .row {
    margin-top: -7vw;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  background: url("../../assets/img/van2.jpg") center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 80px 0 60px 0;
  position: relative;
  background-attachment: fixed;

}

.section-title {
  color: var(--white-color) !important;
}

.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 15px 0 15px 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.contact .info-box i {
  background-color: var(--blue-color);
  color: var(--white-color);
  border-radius: 50%;
  padding: 8px;
}

.contact .info-box h3 {
  font-size: 20px;
  color: var(--yellow-color);
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0;
  color: black !important;
  letter-spacing: 0.02rem;
}

.pad {
  padding-bottom: 0.8rem;
}

.css-13cymwt-control {
  border-radius: 1px !important;
}

.css-13cymwt-control:hover {
  border-color: none !important;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.8);
  padding: 30px;
  background: rgba(255, 255, 255, 0.5);
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 12px 15px;
}

.contact .php-email-form input {
  padding: 10px 15px;
  color: var(--black-color);
}
.css-1jqq78o-placeholder {
  color: var(--black-color);
}
.contact .php-email-form button[type="submit"] {
  background: var(--green-color);
  border: 0;
  border-radius: var(--button-border);
  padding: 0.7rem 2.3rem;
  color: var(--white-color);
  transition: 0.4s;
  margin-top: 0.8rem;
}

.contact .php-email-form button[type="submit"]:hover {
  background: var(--yellow-color);
}



/* popup contact message */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #d6dae7;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background: linear-gradient(to top, #191d23, #232a34);
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
  /* Adjust the width of the popup content */
  animation: fadeIn 0.5s ease-in-out;
  /* Fade in animation */
}

.popup-content h2 {
  font-size: 3vw !important;
  color: var(--white-color) !important;
  margin: 2vw 5vw !important; 
}

.popup h3 {
  margin-bottom: 2vw;
  font-size: 2vw;
  color: var(--white-color);
}

.popup .main-content__checkmark {
  font-size: 5vw;
  color: var(--white-color);
}

.popup i {
  font-size: 2vw;
  color: var(--white-color);
}

.popup .social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 1vw;
  font-size: 2vw;
  color: var(--white-color);
}

.popup .social i:hover {
  color: var(--yellow-color);
}

.popup button {
  display: block;
  padding: .5vw 2vw;
  line-height: 3vw;
  margin-top: 3vw;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  text-align: center;
  border-radius: 50px;
  border: 3px solid var(--green-color);
  color: var(--green-color);
  font-size: 1.5vw;
  font-family: arial;
  position: relative;
  overflow: hidden;
  background: transparent;
  text-transform: uppercase;
  transition: all 0.35s;
}

.popup button:before,
.popup button:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background: var(--green-color);
  z-index: -1;
  transition: all 0.35s;
}

.popup button:before {
  opacity: 0.5;
}

.popup button:after {
  transition-delay: 0.2s;
}

.popup button:hover {
  color: #fff;
}

.popup button:hover:before,
.popup button:hover:after {
  top: 0;
}

.popup button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 2100px) {
  .contact {
    padding: 4vw 0;
  }
  
  .contact .info-box {
    box-shadow: 0 0 2vw rgba(214, 215, 216, 0.6);
    padding: 1vw 0;
  }
  
  .contact .info-box i {
    padding: .7vw;
    font-size: 1.2vw;
  }
  
  .contact .info-box h3 {
    font-size: 1.4vw;
    margin: .8vw 0;
  }
  
  .contact .info-box p {
    line-height: 1.4;
    font-size: 1.05vw;
    margin-bottom: 0;
  }

  .css-1jqq78o-placeholder {
    font-size:1.05vw ;
  }
  .contact .php-email-form {
    box-shadow: 0 0 2vw rgba(214, 215, 216, 0.8);
    padding: 2vw;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    font-size: 1vw;
    padding: 1vw;
  }
  
  .contact .php-email-form input {
    padding: .8vw 1vw;
  }
  .contact .php-email-form button[type="submit"] {
    padding: 0.9vw 2.8vw;
    margin-top: 0.8vw;
    font-size: 1.2vw;
  }
}
@media (min-width: 1400px) {
  .contact .container, .contact  .container-lg, .contact .container-md,.contact .container-sm,.contact .container-xl, .contact .container-xxl {
    max-width: 82%;
}
}

@media (min-width: 992px) {
  .contact {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  .contact {
    background-attachment: scroll;
  }
}

@media (max-width: 575px) {
  .contact {
    background-attachment: fixed;
  }

  .popup h2 {
    font-size: 6.2vw !important;
    margin-bottom: 3vw;
  }

  .popup h3 {
    font-size: 3.5vw !important;
    margin-bottom: 3vw;
  }

  .popup .main-content__checkmark {
    font-size: 8vw !important;
  }

  .popup i {
    font-size: 5vw !important;
  }

  .popup button {
    padding: 2.5vw 5vw !important;
    margin-top: 4vw !important;
    font-size: 2.5vw !important;
  }
}

@media (max-width: 430px) {
  .contact {
    background-attachment: scroll;
  }
  /* popup contact message */

}