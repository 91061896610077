@keyframes shake {
  0% {
    transform: translate(3px, 3px) rotate(0deg);
  }
  10% {
    transform: translate(-3px, -6px) rotate(-1deg);
  }
  20% {
    transform: translate(-9px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(9px, 6px) rotate(0deg);
  }
  40% {
    transform: translate(3px, -3px) rotate(1deg);
  }
  50% {
    transform: translate(-3px, 6px) rotate(-1deg);
  }
  60% {
    transform: translate(-9px, 3px) rotate(0deg);
  }
  70% {
    transform: translate(9px, 3px) rotate(-1deg);
  }
  80% {
    transform: translate(-3px, -3px) rotate(1deg);
  }
  90% {
    transform: translate(3px, 6px) rotate(0deg);
  }
  100% {
    transform: translate(3px, -6px) rotate(-1deg);
  }
}
.bee-container {
  position: absolute; /* Position the bee container */
  top: 40%; /* Align the top of the bee container to the vertical center of the navbar */
  right: -2.5vw; /* Adjust the distance from the right edge of the navbar */
  margin: 0 1vw;
  transform: translateY(
    -50%
  ); /* Adjust the vertical position of the bee container */
}

.bee-image {
  width: 1.8vw; /* Adjust the width of the bee image */
  height: auto; /* Maintain aspect ratio */
  transition: transform 1s;
  z-index: 1000; /* Ensure the bee is above other navbar content */
}
.bee-image:hover {
  transform: translateY(-60px); /* Adjust to account for the initial offset */
}
.shakeAnimation {
  animation: shake 1s infinite;
}

.stopAnimation {
  animation: none !important;
}

@media screen and (max-width: 991px) {
  .bee-image {
    width: 2.8vw; /* Adjust the width of the bee image */
  }

  .bee-container {
    top: 76.5%; /* Align the top of the bee container to the vertical center of the navbar */
    left: auto;
    right: auto;
    margin: 0 2vw;
  }
}
@media screen and (max-width: 430px) {
  .bee-image {
    width: 4.5vw; /* Adjust the width of the bee image */
  }

  .bee-container {
    margin: 0 8vw !important;
  }
}
