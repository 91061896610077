.projectsHeader {
  background: url(../../assets/img/bh-professional-garden-maintenance-north-vancouver.jpg);
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 25vw;
}

.projectsHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25vw;
  background: rgba(0, 0, 0, 0.68);
  z-index: 1;
}

.projectsMain > h2 {
  margin: 3vw;
  text-align: center;
  font-size: 2.5vw;
  text-transform: uppercase;
}

.projectsMain hr {
  margin: 0 10vw;
  border-top: 2px solid;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.projectsBox {
  perspective: 1200px;
  margin: 3vw 10vw;
}
.row{
  margin-left:auto !important;
  margin-right: auto !important;
}

.projectsBox .box {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center center;
  width: 24vw !important;
  height: 23vw !important;
  margin: 1.5vw auto;
  text-align: center;
  filter: saturate(1.2);
}

.projectsBox .box:before {
  content: "";
  position: absolute;
  border: 0.4vw solid rgba(255, 255, 255, 0.5);
  top: 7%;
  left: 7%;
  right: 7%;
  bottom: 7%;
  z-index: 2;
}

.projectsBox .box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.35);
  z-index: -1;
}

.projectsBox .box h4 {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  font-size: 3.4vw;
  font-weight: 600;
  padding: 0 2vw;
  color: var(--white-color);
  transform: translateZ(100px) !important;
}

.projectsBox .box:hover {
  background: var(--yellow-color) !important;
  border-color: #fff;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18) !important;
}

.projectsBox .box:hover:after {
  background: none;
}

.projectsBox .box:hover h4 {
  background-color: none;
}

.box1 {
  background: url(../../assets/img/projects/design/landscape-terrace-design.jpeg);
}

.box2 {
  background: url(../../assets/img/projects/pavingStone/paving-stone-driveway-north-vancouver.jpg);
}

.box3 {
  background: url(../../assets/img/projects/retainingWall/retaining-wall-lighting.jpeg);
}

.box4 {
  background: url(../../assets/img/freshly-mowed-lawn-with-landscaping-truck.jpg);
}

.box5 {
  background: url(../../assets/img/bh-backyard-garden-lawn-design-vancouver.jpg);
}

.box6 {
  background: url(../../assets/img/garden-design-with-stone-retaining-wall.jpg);
}

@media only screen and (max-width: 991px) {

  .projectsBox .box:before {
    border: 0.5vw solid rgba(255, 255, 255, 0.5);
  }
}
@media only screen and (max-width: 575px) {

  .col-4 {
    flex: 0 0 50% !important;
  }
  
  .projectsBox .box {
    width: 34vw !important;
    height: 34vw !important;
    margin: 4vw !important;
  }

  .projectsHeader, .projectsHeader::before {
    height: 30vw;
  }

  .projectsMain > h2 {
    font-size: 3vw !important;
  }

  .projectsBox {
    margin: 5vw 8vw;
  }

  .projectsBox h4 {
    font-size: 5vw !important;
  }
  .projectsBox .box {
    width: 36vw;
    height: 34vw;
    margin: 4vw auto;
  }

  .projectsBox .box:before {
    border: 0.5vw solid rgba(255, 255, 255, 0.5);
  }
}


/* Section styling */
.inspired-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
}

.inspired-container {
  max-width: 80vw;
  width: 100%;
  color: #333;
}

/* Main text */
.inspired-text {
  font-size: 1.8rem;
  line-height: 1.5;
  color: #444;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .inspired-text {
    font-size: 1.5rem;
  }
}

@media (max-width: 430px) {
  .inspired-text {
    font-size: 1.3rem;
  }
}
